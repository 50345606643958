﻿.c-campaign-header {
	grid-area: header;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;

	.c-campaign-header__wrapper {
		display: flex;
	}

	.c-header__logo {
		background: #fff;
		margin: 0;
		padding: 20px;

		@include bp("large") {
			padding-right: 200px;
		}

		@include bp(1360px) {
			padding-left: calc((100vw - 1330px) / 2);
		}

		.c-campaign-header__logo-desktop {
			display: none;

			@include bp("large") {
				display: block;
			}
		}

		.c-campaign-header__logo-mobile {
			display: block;

			@include bp("large") {
				display: none;
			}
		}
	}

	.c-campaign-shape {
		display: none;

		@include bp("large") {
			display: inline-block;
			background: #fff;
			height: 100px;
			aspect-ratio: 29/50;
			-webkit-clip-path: polygon(0 0,100% 0,0 100%);
			clip-path: polygon(0 0,100% 0,0 100%);
		}
	}
}

.c-campaign {

	.c-hero__figure {
		max-height: 500px;
	}

	.c-hero__figure .o-fit.c-hero__image .o-fit__image {
		max-height: 500px;
	}

	@include bp("large") {
		.c-hero__header {
			top: calc(50% - -30px);
		}
	}
}