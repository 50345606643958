// CONTROL.LISTING-STACKED

.c-listing-stacked {
	margin: $bsu 0;
}

.c-listing-stacked__item {}

.c-listing-stacked__item-link {
	@include ui-border("bottom");
	@include link-transition(border-color, background);
	display: block;

	&:hover,
	&:focus {
		border-color: $brand;
		box-shadow: none;
		border-color: $teal;
		background: $grey--lightest;

		&:not(:focus-visible) {
			outline: none;
		}

		.c-listing-stacked__item-heading {
			color: $teal;
		}
	}

	@include bp("small") {
		@include flex-extend;
		justify-content: space-between;
	}
}

.c-listing-stacked__item-figure {
	position: relative;
	overflow: hidden;
	margin-bottom: $bsu;

	@include bp("small") {
		flex: 0 0 auto;
		width: calc(50% - #{$bsu});
		margin-bottom: 0;
	}

	@include bp("medium") {
		width: 360px;
	}
}

.c-listing-stacked__item-body {
	flex: 1 1 auto;
	padding: $ssu;
	color: $body-color;

	.c-listing-stacked__item-figure + & {

		@include bp("small") {
			padding-top: $ssu;
			padding-left: $bsu;
		}

		@include bp("medium") {
			padding-left: $bsu;
		}

		@include bp("large") {
			padding-top: $bsu;
			padding-left: $lsu;
			padding-right: $bsu;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-listing-stacked__item-heading {
	@include text-heavy(18px, 24px);
	margin-bottom: $ssu;

	a {
		@include link-transition;

		&:hover,
		&:focus {
			color: $brand;
		}
	}
}

.c-listing-stacked__item-text {
	@include text-book(16px, 28px);
	margin-bottom: 0;
}

.c-listing-stacked__item-date {
	@include carousel-item-date;
	display: block;
	margin-bottom: $ssu;
}

.c-listing-stacked__item-category {
	@include carousel-item-category;
}