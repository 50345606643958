// control.rte

.c-rte {
	@include control;

	.c-two-column + & {
		// 1.5em is the standard paragraph spacing
		@include bp-below("large") {
			margin-top: calc(1.5em + -1 * var(--control-gap));
		}

		@include bp("large") {
			margin-top: calc(1.5em + -1 * var(--control-gap));
		}
	}
}

.mce-content-body,
.c-rte {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}
}

.c-rte__layout {
	@include layout-wrapper;
}

.c-rte__alignment--flush {
	@include layout-wrapper--narrow("left");
}

.c-rte__alignment--inset {
	@include layout-wrapper--narrow("centre");
}

.c-rte__body-text {
	@include body-text;

	li {
		@include text-book(16px, 24px);
		margin-bottom: 1em;
	}
}
