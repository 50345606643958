// COMPONENT.EXPERIENCE-HERO

$experience-hero-bp: "large";

.c-experience-hero {
	@include flex-extend;
	align-items: stretch;

	overflow: hidden;
	position: relative;

	height: 50vh;
	min-height: 400px;
	max-height: 80vh;
}

.c-experience-hero__image {
	@include bp($experience-hero-bp) {
		width: 100%;
		height: 100%;
		padding-bottom: 0 !important;
		height: 100%;

		> img,
		> noscript > img {
			// Adjust image position to be vertically centred, taking sticky header into account
			@supports (object-position: 100% calc(50% + (var(--header-height) / 2))) {
				object-position: 100% calc(50% + (var(--header-height) / 2));
			}
		}
	}
}

.c-experience-hero__actions {
	@include list-reset;

	position: absolute;
	bottom: $bsu;
	right: $bsu;

	@include flex-extend;
}

.c-experience-hero__action {
	padding-left: $bsu;
}

.c-experience-hero__action-link {
	@include button;
}
