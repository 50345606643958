﻿// CONTROL.VIDEO-GROUP

@use "sass:math";

.c-videogroup {
	@include control;
	@include layout-wrapper;
}

.c-videogroup__layout {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.c-videogroup__heading {
	@include control__heading;
	margin-bottom: 40px;
	width: 100%;
}

.c-videogroup__cover {
	@include abs-center;
	width: 100%;
}


.c-videogroup__item-heading {
	@include text-black(20px, 28px);
	margin-top: 15px;
	color: $brand;
	transition: 0.3s ease-in all;
}

.c-videogroup__item {
	display: block;
	position: relative;
	overflow: hidden;

	&:hover,
	&:focus {

		.c-video-group__item-heading {
			color: $brand--alt;
		}
	}
}

.c-videogroup__thumbnail {
	padding-bottom: math.div(9, 16);

	img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
}

.c-video__layout {
	.c-videogroup & {
		width: 100%;

		@supports (display: grid) {
			padding: 0;
		}

		@include bp("large") {
			width: 33.33%;

			@supports (display: grid) {
				width: 100%;
			}
		}
	}
}

.c-video__heading {
	.c-videogroup & {
		@include text-heavy(18px, 24px);
		padding: $bsu;
	}
}

@supports (display: grid) {
	.c-videogroup__layout {
		display: grid;
		column-gap: $bsu;
		grid-template-columns: repeat(1, 1fr);

		@include bp("medium") {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.c-videogroup__heading {
		@include bp("medium") {
			grid-column: 1/-1;
		}
	}
}


@include bp(large) {
	.c-videogroup {
		& + .c-videogroup {
			margin-top: 80px;
		}
	}
}
