﻿// PLUGINS.BAMBOO-HR

#BambooHR {
	max-width: 920px;
	// Main Heading
	.BambooHR-ATS-board > h2 {
		@include text-black(28px, 30px);
		margin: 0 0 $bsu !important;
		color: $black;
		border-bottom: 1px solid $grey--lighter !important;
	}
	// Department
	.BambooHR-ATS-Department-Item {
		.BambooHR-ATS-Department-Header {
			@include text-heavy(18px, 24px);
			margin: 0 0 10px;
			color: $black;
		}

		.BambooHR-ATS-Jobs-List {
			padding: 0 0 20px !important;
			margin: 0 0 20px !important;
			border-bottom: 1px solid $grey--lighter !important;

			a {
				@include text-book(16px, 24px);
				color: var(--link);
				font-weight: normal;

				&:hover,
				&:focus {
					color: var(--link-hover);
				}
			}
		}

		.BambooHR-ATS-Jobs-Item {
			margin: 10px 0 0 !important;

			&:first-child {
				margin: 0 !important;
			}
		}

		.BambooHR-ATS-Location {
			//float: none !important;
			margin-left: 10px;
		}
	}
}