// OBJECT.BTN

// Shared button styles

@mixin button-size($size: "medium", $icon: false) {
	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@if ($size == "large") {
			@include text-heavy(18px, 30px);
			padding: 14px 32px;
			@include radius;

			span[class^=iconf] {
				font-size: 28px;
			}
		}
		@else if ($size == "medium") {
			@include text-heavy(16px, 24px);
			padding: 12px 28px;
			@include radius;

			span[class^=iconf] {
				font-size: 24px;
			}
		}
		@else if ($size == "small") {
			@include text-heavy(12px, 16px);
			padding: 10px 24px;

			span[class^=iconf] {
				font-size: 16px;
			}
		}
		@else if ($size == "smallest") {
			@include text-heavy(12px, 16px);
			padding: 6px 16px;
		}
		@else {
			@warn "Unknown button size #{$size}.";
		}
	}
}

@mixin button-icon-size($size: "medium") {
	
	@if ($size == "large") {
		height: 76px;
		width: 76px;
		font-size: 28px;
		/*line-height: #{74px - (23px * 2)};*/
		/*padding: 23px;*/
	}
	@else if ($size == "medium") {
		height: 50px;
		width: 50px;
		font-size: 24px;
		/*line-height: #{54px - (15px * 2)};*/
		/*padding: 14px;*/
	}
	@else if ($size == "small") {
		height: 36px;
		width: 36px;
		font-size: 16px;
		/*line-height: #{40px - (11px * 2)};*/
		/*padding: 11px;*/
	}
}

@mixin button($size: "small", $icon: false) {
	@include link-transition;
	@include radius;
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	border-radius: 50px !important;
	box-shadow: 0 1px 2px rgba(0,0,0,0.08), 0 4px 12px rgba(0,0,0,0.05);
	background: $brand;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	text-align: center;
	text-decoration: none !important;
	justify-content: center;
	height: fit-content;
	width: fit-content;
	transition: all 0.3s;

	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@include button-size($size);
	}

	&:hover {
		box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
		transition: all 0.3s;
	}

	&:disabled {
		cursor: not-allowed;
		background: $white !important;
		color: $grey !important;
		border: none !important;
		box-shadow: inset 0 0 0 1px $grey--light !important;
	}
}

@mixin button--text {
	padding: 0;
	border: none;
	background-color: transparent;
}

@mixin btn-primary {
	background: $aquamarine;
	border: 1px solid $aquamarine;
	color: $grey--darkest;

	&:hover {
		background: $teal;
		border: 1px solid $teal;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}

	&:focus,
	&:active {
		background: $black;
		border: 1px solid $black;
		color: $white;
	}

	&:disabled, [disabled] {
		background: $grey--light;
		border: 1px solid $grey--light;
		color: $white;
	}
}

@mixin btn-secondary {
	background: $black;
	border: 1px solid $black;
	color: $white;

	&:hover {
		background: $teal;
		border: 1px solid $aquamarine;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
		color: $grey--darkest;
	}

	&:focus,
	&:active {
		background: $black;
		border: 1px solid $black;
		color: $white;
	}

	&:disabled, [disabled] {
		background: $grey--light;
		border: 1px solid $grey--light;
		color: $white;
	}
}

@mixin btn-outline {
	background: none;
	border: 1px solid $tropical-rain-forest;
	color: $tropical-rain-forest;

	&:hover {
		background: $teal25;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}

	&:focus,
	&:active {
		background: $aquamarine;
	}

	&:disabled, [disabled] {
		background: none;
		border: 1px solid $grey--light;
		color: $grey--light;
	}
}


@mixin label-primary {
	background: $aquamarine;
	border: 1px solid $aquamarine;
	color: $grey--darkest;

	&:hover {
		background: $tropical-rain-forest;
		border: 1px solid $tropical-rain-forest;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
		color: $aquamarine;
	}

	&:focus,
	&:active {
		background: transparent;
		border: 1px solid $tropical-rain-forest;
		color: $tropical-rain-forest;
	}

	&:disabled, [disabled] {
		background: $grey--light;
		border: 1px solid $grey--light;
		color: $white;
	}
}

@mixin label-secondary {
	background: $grey--lightest;
	border: 1px solid $grey--lightest;
	color: $tropical-rain-forest;

	&:hover {
		background: $teal25;
		border: 1px solid $teal25;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}

	&:focus,
	&:active {
		background: $aquamarine;
		border: 1px solid $aquamarine;
		color: $grey--darkest;
	}

	&:disabled, [disabled] {
		background: $grey--light;
		border: 1px solid $grey--light;
		color: $white;
	}
}