// COMPONENT.EXPERIENCE-TILE

.c-experience-tile {
	@include flex-extend;
	height: 100%;
	@include ui-border('all');

	flex-direction: column;
	justify-content: space-between;

	position: relative;
}

.c-experience-tile__figure {}

.c-experience-tile__highlight {
	position: absolute;
	top: 0;
	left: 0;

	@include text-black(10px, 1.6, null, 4%);
	padding: $tsu $ssu;
}

// When there is no figure
.c-experience-tile__highlight:first-child + .c-experience-tile__body {
	margin-top: $bsu;
}

.c-experience-tile__body {
	flex: 1 0 auto;
	padding: $bsu;
}

.c-experience-tile__subheading {
	display: block;
	margin-bottom: $ssu;

	@include text-book(14px, 22px);
	color: $body-color;
	word-wrap: break-word;
}

.c-experience-tile__heading {
	display: block;
	margin-bottom: $ssu;

	@include text-black(20px, 28px);
	@include link-transition;
}

.c-experience-tile__text {
	display: block;

	@include text-book(16px, 24px);
	color: $body-color;

	> :last-child {
		margin-bottom: 0;
	}
}

.c-experience-tile__actions {
	@include flex-extend;
	@include flex-wrap;
	justify-content: center;

	padding: 0 $bsu $bsu;
}

.c-experience-tile__btn {
	flex: 1 0 auto;
}