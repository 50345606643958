// component.page-header

.c-page-header {
	padding: $lsu 0;
	background: $grey--much-lighter;

	@include bp("medium") {
		padding: $lsu * 1.5 0;
	}

	@include bp("large") {
		padding: $hsu 0;
	}
}

.c-page-header__layout {
	@include layout-wrapper;
}

.c-page-header__lede,
.o-layout-header__lede {
	@include text-book(20px, 28px);
	margin: 0 auto;

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-page-header__date {
	@include text-book(15px, 26px, null, 4%);
	margin-bottom: 5px;
	display: inline-block;
}

.c-page-header__author {
	@include text-book(15px, 26px, null, 4%);
	margin-bottom: 2em;
	display: inline-block;
	text-transform: capitalize;

	span {
		padding: 0 10px;
	}
}
