﻿.c-footer-controls {
	@include control;
	@include layout-wrapper;
}

.c-footer-controls__heading {
	@include control__heading;
}

.c-footer-controls__list {
	padding: 0;
}