﻿// MACRO.BLOCKQUOTE

.m-quote {
	@include body-text__figure;
	padding-left: $lsu;
	position: relative;

	&:before {
		@include pseudo-element;
		left: 0;
		width: 5px;
		height: 100%;
		background: linear-gradient(180deg, #00B2A5 0%, #55EFC4 100%);
	}
}

.m-quote__text {
	@include text-heavy(18px, 24px);
	color: $tropical-rain-forest;
}

.m-quote__citation {
	@include text-book(14px, 24px, italic);
	color: $body-color;
}
