// control.case-study

@use "sass:math";

$case-study-bp: "large";

.c-case-study {
	@include control;
}

.c-case-study__layout {
	@include layout-wrapper;
}

.c-case-study__content {
	@include radius;

	@include bp($case-study-bp) {
		@include flex-extend;
		align-items: stretch;
	}
}

.c-case-study__figure {
	@include figure;

	@include bp($case-study-bp) {
		flex: 1 0 50%;
	}
}

.c-case-study__image {
	height: 100%;
}

.c-case-study__body {
	@include text-book(16px, 24px);
	padding: $lsu $bsu;
	position: relative;

	&:before {
		position: absolute;
		display: block;
		content: '';
		height: 4px;
		background: linear-gradient(90deg, #00B2A5 15.19%, #6BECCC 82.25%);
		top: 0;
		width: calc(100% - (#{$bsu} * 2));
	}


	@include bp($case-study-bp) {
		flex: 1 0 50%;
		padding: 7.5% 5%;

		&:before {
			width: 80%;
		}
	}



	> *:last-child {
		margin-bottom: 0;
	}
}


.c-case-study__eyebrow {
	@include text-black(14px, 16px);
	color: $tropical-rain-forest;
	display: block;
	margin-bottom: $ssu;

	@include bp($case-study-bp) {
		@include text-black(16px, 24px);
	}
}

.c-case-study__heading {
	@include text-black(20px, 24px);
	margin-bottom: $ssu;

	@include bp($case-study-bp) {
		@include text-black(28px, 30px);
		margin-bottom: 15px;
	}
}

.c-case-study__text {
	@include text-book(16px, 24px);
	margin-bottom: $bsu;
}

.c-case-study__video {
	@include embed;
	display: block;
	padding-bottom: percentage(math.div(9, 16));
	cursor: pointer;
}

.c-case-study__video-icon {
	@include playbutton;
}

.c-case-study__link {
	@include button("small");
	@include btn-primary;
}
