﻿// COMPONENT.SHARE

$share-icon-size: 46px;
$share-icon-font-size: 20px;

$share: #51ae5e;
$facebook: #3b5997;
$twitter: #55acef;
$pinterest: #bd2126;
$google: #d34836;
$weibo: #fa7d3c;

.c-share {
	margin: $lsu auto;
	text-align: center;

	.l-page__sidebar & {
		margin-top: 0;
	}
}

.c-share__layout {
	@include layout-wrapper;
}

.c-share--floating {
	transition: left 0.3s ease-in;
	position: fixed;
	left: -#{$share-icon-size};
	top: 200px;
	z-index: $z-share;
	width: $share-icon-size;
	padding: 0;
	margin: 0;

	&.is-visible {
		left: 0;
	}
}

.c-share__heading {
	@include text-heavy(13px, 16px);
	display: block;
	margin: 0 0 $bsu;

	.c-share--floating & {
		display: none;
	}

	.l-page__sidebar & {
		text-align: left;
	}
}

.c-share__items {
	@include list-reset;
	@include flex-extend;
	@include flex-wrap;
	justify-content: center;
	margin: -#{$ssu} 0 0 -#{$ssu};
	.l-page__sidebar & {
		justify-content: flex-start;
	}
}

.c-share__item {
	padding: $ssu 0 0 $tsu;
}

.c-share__icon {
	@include icon-wrapper;
	@include link-transition;
	@include button('medium', true);
	@include btn-primary;
	display: block;
	vertical-align: middle;
}
