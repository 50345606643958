﻿// control.food-menu

.c-food-menu {
	@include control;
}

.c-food-menu__layout {
	@include layout-wrapper;
}

.c-food-menu__heading {
	@include control__heading;
}

.c-food-menu__intro {
	@include text-book(20px, 28px);

	*:last-child {
		margin-bottom: 0;
	}
}

.c-food-menu__notes {
	@include text-book(16px, 24px);
	padding-top: $lsu;

	*:last-child {
		margin-bottom: 0;
	}
}


.c-food-menu__section {
	padding-top: $lsu;
}

.c-food-menu__section-title {
	@include text-heavy(24px, 28px);
}

.c-food-menu__section-intro {
	@include text-book(16px, 24px);
	padding-top: $tsu;

	*:last-child {
		margin-bottom: 0;
	}
}

.c-food-menu__section-notes {
	@include text-book(16px, 24px);
	padding-top: $ssu;

	*:last-child {
		margin-bottom: 0;
	}
}

.c-food-menu__items {
	@include list-reset;
}

.c-food-menu__item {
	display: flex;
	gap: $lsu;
	align-items: flex-end;
	justify-content: space-between;
	padding: $bsu 0;
	border-bottom: 1px dashed $grey--dark;
}

.c-food-menu__item-name {
	@include text-black(16px, 28px);
}

.c-food-menu__item-description {
	@include text-book(16px, 24px);
	padding-top: $tsu;

	*:last-child {
		margin-bottom: 0;
	}
}

.c-food-menu__item-prices {
	@include list-reset;
}

.c-food-menu__item-price {
	display: flex;
	gap: $bsu;
	align-items: center;
}

.c-food-menu__item-price-name {
	@include text-book(14px, 22px);
}

.c-food-menu__item-price-amount {
	@include text-black(16px, 28px);
}


/*
.c-food-menu__tab-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;
	justify-content: center;
}

.c-food-menu__tab-button {
	@include button-reset;
	padding: $bsu;

	@include bp("large") {
		padding: $bsu $lsu;
	}

	@include text-black(16px, 28px);
	color: $brand;
	position: relative;

	&::after {
		content: "";
		@include abs-center-h;
		bottom: 0;
		width: 0;
		height: 4px;
		opacity: 0;
		background: $black;

		@include link-transition;
	}

	&[aria-expanded="true"],
	&:hover,
	&:focus {
		color: $black;

		&:not(:focus-visible) {
			outline: none;
		}

		&::after {
			width: 40px;
			opacity: 1;
		}
	}
}

.c-food-menu__tab-body {
	&[aria-expanded="false"] {
		display: none;
	}

	&[aria-expanded="true"] {
		display: block;
	}
}*/
