// COMPONENT.FOOTER

@use "sass:math";

$footer-logo-width: 150px;

.c-footer {
	position: relative;
	background: $black url('../images/interface/FooterPattern.svg') no-repeat right bottom;
}

.c-footer__layout {
	@include layout-wrapper;
}

.c-footer__wrapper {
	display: block;

	@include bp("medium") {
		display: flex;
	}
}

/* Enquiry */

.c-footer__enquiry {
	background: linear-gradient(90deg, #00B2A5 25.37%, #6BECCC 71.06%);
}

.c-footer__enquiry-body {
	padding: 50px 0;
	display: flex;
	align-items: center;
}

.c-footer__enquiry-wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 20px;

	@include bp("medium") {
		flex-direction: row;
		gap: 60px;
	}
}

.c-footer__enquiry-heading {
	@include text-black(22px, 24px);
	flex: 0 1 30%;
	position: relative;
	padding-bottom: 20px;
	padding-right: 0;

	@include bp("medium") {
		@include text-black(38px, 40px);
		flex: 0 1 30%;
		position: relative;
		padding-bottom: 0;
		padding-right: 60px;
	}

	&::after {
		@include abs-center-h;
		content: '';
		width: 90px;
		bottom: 0;
		height: 1px;
		background: $tropical-rain-forest;
		margin: 0;

		@include bp("medium") {
			@include abs-center-v;
			content: '';
			width: 1px;
			right: 0;
			height: 50px;
			background: $tropical-rain-forest;
			margin: 0;
		}
	}
}

.c-footer__enquiry-text {
	margin: 0;
	flex: 1;
}

.c-footer__enquiry-link {
	@include button("medium");
	@include btn-secondary;
}
/*====---- Main */
/* Left - Social */

.c-footer__main {
	padding: 40px 0;
	color: $white;

	@include bp("medium") {
		@include flex-extend;
		padding: 70px 0;
	}
}

.c-footer__social-container {
	width: 100%;
	margin-bottom: 30px;

	@include bp("medium") {
		/*width: 40%;*/
		width: 400px;
		margin-bottom: 0px;
	}
}

.c-footer__signup-text {
	max-width: 100%;
	margin: 0 0 20px 0;
	@include text-book(18px, 28px);

	@include bp("medium") {
		max-width: 370px;
		margin: 0 40px 20px 0;
		@include text-book(20px, 28px);
	}
}

.c-footer__signup-button {
	@include button("medium");
	@include btn-primary;
}

.c-footer__social {
	padding-top: 40px;

	@include bp("medium") {
		padding-top: 50px;
	}
}

.c-footer__social-heading {
	display: block;
	margin-bottom: $ssu;
	color: $white;
}

.c-footer__social-list {
	@include list-reset;
}

.c-footer__social-item {
	display: inline-block;
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-footer__social-link {
	@include icon-wrapper;
	@include circle(40px);
	@include link-transition;
	border: 1px solid $teal;
	font-size: 20px;
	color: $teal;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $brand;
		background-color: $teal;
	}
}
/* Right - Navigation */

.c-footer__links-container {
	width: 100%;

	@include bp("medium") {
		flex: 1 1 auto;
	}
}

.c-footer__links-header {
	@include text-black(20px, 24px);
	margin-bottom: 45px;
	color: $teal;

	@include bp("medium") {
		@include text-black(28px, 30px);
		margin-bottom: 50px;
	}
}

.c-footer__links-wrapper {
	display: block;
}

.c-footer__addresses {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.c-footer__addresses-section {
	padding-right: 20px;
	margin-bottom: 40px;
	width: 100%;

	@include bp('small') {
		width: 50%;
	}

	@include bp('large') {
		width: 25%;
	}
}

.c-footer__addresses-heading {
	@include text-heavy(14px, 16px);
	margin-bottom: 10px;

	@include bp("medium") {
		@include text-heavy(16px, 18px);
		margin-bottom: 20px;
	}
}

.c-footer__addresses-details {

	p {
		margin: 0px;
	}

	a {
		color: $aquamarine;
		@include link-transition;

		&:hover {
			color: $teal;
		}
	}
}

.c-footer__legal {
	width: 100%;

	@include bp("medium") {			
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.c-footer__legal-links {
	@include list-reset;
	flex: 1 1 auto;
}

.c-footer__legal-links-item {
	display: block;
	margin-bottom: 20px;

	@include bp('medium') {
		display: inline-block;
		margin-right: $lsu;
	}
	@include bp('large') {
		margin-right: $hsu;
	}
}

.c-footer__legal-link {
	@include text-heavy(16px, 18px);
	color: $white;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__copyright {
	@include text-book(12px, 16px);
	display: block;
	margin-top: $hsu;
	color: $white;

	@include bp('medium') {
		margin-top: 0;
	}
}


/* Back to Top */
.c-footer__back-to-top-wrapper {
	@include layout-wrapper;
	text-align: right;
}

.c-footer__back-to-top {
	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	width: 54px;
	height: 54px;
	font-size: 24px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;
	border-radius: 50%;
	transition: all 0.3s;
	background: $aquamarine;
	color: $black;

	&:hover {
		background: $teal;
	}

	&::before {
		@include font-icon;
		content: $iconf-arrow-up;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				bottom: 80px;
				right: 30px;
				transform: translateY(-50%);
				width: 54px;
				height: 54px;
				border: none;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
