/* TOOLS.MIXINS */

@use "sass:math";
@use "sass:list";

@import "tools.functions";

// Font Icons
@mixin font-icon {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Breakpoint mixin
// Uses $breakpoints variable set in _config.scss file
@mixin bp($size) {

	@if (type-of($size) == "number") {
		@media (min-width: $size) {
			@content;
		}
	}
	@else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (min-width: $start) {
					@content;
				}
			}
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

@mixin bp-below($size) {

	@if (type-of($size) == "number") {
		@media (max-width: #{em($size - 1px)}) {
			@content;
		}
	}
	@else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (max-width: #{$start - 1px}) {
					@content;
				}
			}
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

// Positioning
@mixin abs-center {
	position: absolute;
	right: 50%;
	top: 50%;
	transform: translate(50%, -50%);
}

@mixin abs-center-h {
	position: absolute;
	right: 50%;
	transform: translateX(50%);
}

@mixin abs-center-v {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

// Border radius

@mixin radius {
	border-radius: $radius;
	overflow: hidden;
}

// Play Button

@mixin playbutton {
	@include abs-center;
	@include link-transition;
	@include circle(72px);
	background: rgba($white, 0.5);
	border: 4px $white solid;

	&::before {
		@include pseudo-element;
		left: 48px;
		top: 32px;
		transform: translate(-50%, -50%);
		border-width: 16px 28px;
		border-color: transparent;
		border-left-color: $white;
		border-style: solid;
		@include link-transition;
	}

	&:hover {
		background: $teal;
	}
}

// Layout
@mixin layout-split($side, $split, $width: $layout-wrapper-max-width, $gutter: $bsu) {
	// $side should be either left or right
	// $split should be a percentage
	// Example use:
	// @include layout-split(left, 40%);
	// To be used elements that need to extend to the edge of the page,
	// so it can't be wrapped in a layout-wrapper, but with content that
	// needs to align with indented content as though it were in a wrapper.
	// Because it uses % padding, it's important that any element using this
	// sits within a container with a specified width. That width should be
	// the same as $split as a proportion of the screen width

	$counter-split: 100% - $split;
	$ratio: math.div($counter-split, $split);

	$growthRate: #{(math.div(($ratio - 1), 2) + 1) * 100%};
	$normaliser: #{math.div($width, 2) - $gutter};

	@include bp($width) {
		padding-#{$side}: calc(#{$growthRate} - #{$normaliser});
	}
}

/**
 * The "RAM" pattern is Repeat Auto Minmax, which allows items in a grid
 * to reflow automatically with the goal of putting as many items as
 * possible on a row while also never going below a minimum size
 */
@mixin grid-ram($size) {
	grid-template-columns: repeat(auto-fit, minmax($size, 1fr));
}

// Transition
/**
 * Applies a transition in a consistent way.
 *
 * $props can either be a single property or a ", " separated list
 * of property strings.
 */
@mixin link-transition($propString: all, $speed: 0.25s) {
	$props: string-split($propString);
	$transitions: props-to-transitions($props, $speed);

	/* autoprefixer: ignore next */
	transition: $transitions;

	@media (prefers-reduced-motion) {
		transition: none;
	}
}

@mixin scroll-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		&:not([data-scroll-appear="true"]) {
			@content;
		}
	}
}

// Clearfix
@mixin clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin remove-clearfix {
	&::after {
		display: none;
		clear: none;
	}
}

// Pseudo Element
@mixin pseudo-element {
	content: '';
	display: block;
	position: absolute;
}

// Reliable Flexbox fallback mixin
// 1. Unsupported
// 2. Old - iOS 6-, Safari 3.1-6
// 3. Old - Firefox 19- (buggy but mostly works)
// 4. Tweener - IE 10
// 5. New - Chrome
// 6. New, Spec - Opera 12.1, Firefox 20+
@mixin flex-extend {
	display: block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	/* autoprefixer: ignore next */
	display: flex;
}

@mixin flex-wrap {
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	/* autoprefixer: ignore next */
	flex-wrap: wrap;
}

@mixin flex-nowrap {
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	/* autoprefixer: off */
	flex-wrap: nowrap;
}

// Placeholder Text
@mixin placeholder-text ($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}

	&:-moz-placeholder {
		color: $color;
	}

	&:-ms-input-placeholder {
		color: $color;
	}
}

// Mixin to add square dimensions to an element i.e. the same width and height
// Example: Create a square 40em high and wide
// @include tb_square ( 40em );
@mixin square($size) {
	width: $size;
	height: $size;
}

// Mixin to add circle dimensions to an element i.e. adds square dimensions and border-radius of (dimensions / 2)
// Example: Create a circle 40em high and wide
// @include tb_square ( 40em );
@mixin circle($size) {
	@include square($size);
	border-radius: math.div($size, 2);
}

// UI Border mixin
$ui-border: 1px solid $base-ui-color;

@mixin ui-border($side) {
	@if ($side == 'all') {
		border: $ui-border
	}
	@else if ($side == 'top') {
		border-top: $ui-border;
	}
	@else if ($side == 'right') {
		border-right: $ui-border;
	}
	@else if ($side == 'bottom') {
		border-bottom: $ui-border;
	}
	@else if ($side == 'left') {
		border-left: $ui-border;
	}
}

// Resets
@mixin button-reset {
	// Used to reset styles on <button> elementss
	// This can be used for interactive elements that aren't links
	background: transparent;
	border: none;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
}

@mixin link-plain {
	@include link-transition;
	color: inherit;
}

// Forms
@mixin form-input {
	// iOS zooms in unless font size in inputs is at least 16px
	@include text-book(16px, 22px);
	display: block;
	width: 100%;
	// 11px top and bottom padding makes them 48px tall, same as buttons
	padding: $ssu;
	border: 1px solid $grey;
	border-radius: 4px;
	background: $white;
	transition: 0.3s;
	outline: none;
	color: $grey--darker;
	-webkit-appearance: none !important;

	&:focus {
		border: 1px solid $brand;
	}

	&.input-validation-error {
		border-color: $error;
		background: rgba(251, 194, 196, 0.1);
	}
}

$outline-default: #4d90fe;

@mixin focus-outline($color: $outline-default) {
	// Replicate Chrome's default focus style
	outline-color: $color;

	@if ($color == $outline-default) {
		@supports (outline-color: -webkit-focus-ring-color) {
			outline-color: -webkit-focus-ring-color;
		}
	}
	// IE11 and Edge don't support outline-style: auto
	outline-style: solid;
	outline-width: 2px;

	@supports(outline-style: auto) {
		outline-style: auto;
	}
}

@mixin triangle($direction, $height) {
	@include pseudo-element;
	height: $height;
	aspect-ratio: 29 / 50; /*  <===  Apparently this is the ratio resulting in 60deg angle   */

	@if $direction == upperleft {
		clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
	}

	@if $direction == upperright {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
	}

	@if $direction == bottomleft {
		clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
	}

	@if $direction == bottomright {
		clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
	}
}

@mixin hover-bounce($distance: -18px) {
	transition: all 0.25s ease-in-out;
	transform: translateY(0px);

	&:hover {
		transform: translateY($distance);
		transition: all 0.25s ease-in-out;
	}
}

@mixin flickity-buttons {
	.flickity-button {
		@include font-icon;
		font-size: 20px;
		color: $black;
		background: $aquamarine;
		width: 44px;
		height: 44px;
		border-radius: 100%;
		position: absolute;
		transition: all 0.3s;
		padding: 0;

		&:hover {
			background: $teal;
		}
	}

	.flickity-button-icon {
		display: none;
	}

	.flickity-button.previous {
		left: $ssu;

		&::after {
			content: $iconf-arrow-left;
			height: 24px;
			width: 24px;
			padding: 10px;
		}
	}

	.flickity-button.next {
		right: $ssu;

		&::after {
			content: $iconf-arrow-right;
			height: 24px;
			width: 24px;
			padding: 10px;
		}
	}
}

@mixin carousel-container {
	@include flickity-buttons;
	@include control;
	position: relative;
	margin-bottom: $hsu;

	.flickity-enabled:focus {
		outline: none;
	}

	@include bp("medium") {
		margin-bottom: ($lsu * 2);
	}
}

@mixin carousel-container-link {
	@include text-heavy(14px, 20px);
	@include link-transition;
	@include custom-text-link;
	display: flex;
	margin-bottom: $bsu;
	text-decoration: none;

	span {
		margin-left: 5px;
		transition: all 0.3s;
	}

	@include bp("small") {
		display: inline-flex;
		align-items: center;
		margin-left: $lsu;
		margin-bottom: 0;
	}

	&:hover {
		span {
			margin-left: 10px;
		}
	}
}

@mixin carousel-container-buttons {
	.flickity-prev-next-button {
		display: none;
		top: auto;
		bottom: -60px;

		@include bp('small') {
			margin: 0 $ssu -30px 0;
			bottom: -30px;
		}

		@include bp("medium") {
			top: -10px;
			margin-top: -35px;
		}
	}

	.flickity-prev-next-button.previous {
		left: $bsu;

		@include bp('medium') {
			left: auto;
			right: 60px;
		}
	}

	.flickity-prev-next-button.previous {

		@include bp('medium') {
			left: auto;
			right: 60px;
		}
	}

	.flickity-enabled {
		.flickity-prev-next-button {
			display: block;
		}
	}
}

@mixin carousel-list {
	width: calc(100% + #{$bsu});
	margin-left: -$ssu;
	margin-right: -$ssu;

	&:not(.flickity-enabled) {
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(4);

		width: 100%;
		margin-left: 0;
		margin-right: 0;

		@include bp("small") {
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp("medium") {
			grid-template-columns: repeat(3, 1fr);
		}

		@include bp("large") {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

@mixin carousel-item {
	@include hover-bounce;
	display: grid;
	gap: 20px;
	grid-row: span 5;
	grid-template-columns: subgrid;
	grid-template-rows: subgrid;
	color: $black;
	width: 100%;
	margin-bottom: $bsu;

	&:hover {
		img {
			transform: scale(1.1);
			transition: all 0.3s;
		}
	}

	.flickity-enabled & {
		width: 100%;
		transition: none;
		margin-top: 20px;
		transition: transform 0.3s;
		padding: 0 $ssu;

		@include bp("small") {
			width: 50%;
		}

		@include bp("medium") {
			width: 33.33%;
		}

		@include bp("large") {
			width: 25%;
		}
	}
}

@mixin carousel-item-image {
	border-top: 3px solid $teal;
	height: 200px;
	position: relative;
	overflow: hidden;
	background: $grey--much-lighter url(/Content/images/interface/logo/RMV-logo.svg) no-repeat center center;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		transition: all 0.3s;
	}
}

@mixin carousel-item-category {
	@include text-heavy(13px, 16px);
	position: absolute;
	right: 5px;
	top: 5px;
	background: $black;
	color: $white;
	padding: 5px 15px 3px;
	border-radius: 50px;
}

@mixin carousel-item-heading {
	@include text-heavy(18px, 24px);
	transition: all 0.25s ease-in-out;

	.c-pathway-carousel__item:hover &,
	.c-article-list__item:hover & {
		color: $teal;
	}

	.flickity-enabled & {
		height: 70px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

@mixin carousel-item-date {
	@include text-book(13px, 16px);
}

@mixin carousel-item-copy {
	@include text-book(16px, 24px);

	p {
		margin: 0;
	}

	.flickity-enabled & {
		height: 190px;
		display: -webkit-box;
		-webkit-line-clamp: 8;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

@mixin carousel-item-link-text {
	color: $tropical-rain-forest;
	@include text-heavy(14px, 20px);
	display: inline-flex;
	align-items: center;
	transition: all 0.3s;

	&:after {
		@include font-icon;
		content: $iconf-arrow-right;
		display: inline-block;
		color: $teal;
		margin-left: 5px;
		transition: all 0.3s;

		.c-article-list__item:hover & {
			margin-left: 10px;
		}
	}

	.c-pathway-carousel__item:hover &,
	.c-article-list__item:hover & {
		color: $teal;

		span {
			margin-left: 10px;
		}
	}
}
