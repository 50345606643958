﻿// control.expand-collapse

.l-page__main-controls,
.l-page__footer-controls {
	.c-expand-collapse__layout > div {
		@include layout-wrapper--narrow("left");
	}
}

.c-expand-collapse {
	@include control;
}

.c-expand-collapse__layout {
	@include layout-wrapper;
}

.c-expand-collapse__heading {
	@include control__heading;
}

.c-expand-collapse__initial-content {
	@include body-text;
	p {
		margin-bottom: $bsu;
	}
}

.c-expand-collapse__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-right: $bsu * 3;
	background-color: $white;
	cursor: pointer;

	&:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-expand-collapse__item-btn {
	@include button;
	@include button--text;
	box-shadow: none;
	position: relative;
	display: flex;
	margin: $bsu 0 0;
	justify-content: center;
	align-items: center;
	position: relative;
	padding-left: 3.5em;
	background: transparent;
	overflow: visible;
	cursor: pointer;

	&:hover {
		box-shadow: none;

		[class*="iconf"] {
			background: $teal50;
		}
	}

	[class*="iconf"] {
		@include button('small', true);
		@include btn-primary;
		@include abs-center-v;
		left: 0;
	}
}

.c-expand-collapse__item-btn-icon {
	font-size: 16px;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		&::before {
			content: $iconf-minus;
		}
	}
}

.c-expand-collapse__item-btn-text {
	@include text-heavy(16px, 24px);
	color: $tropical-rain-forest;
}

.c-expand-collapse__item-btn--open {
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: none;
	}
}

.c-expand-collapse__item-btn--close {
	.c-expand-collapse__item:not([aria-expanded="true"]) & {
		display: none;
	}
}

.c-expand-collapse__item-body {
	@include body-text;
	display: none;
	padding: $ssu 0;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}
}
