﻿// CONTROL.FAQ

.c-faq {
	@include control;
}

.c-faq__layout {
	@include layout-wrapper;
}

.c-faq__heading {
	@include control__heading;
}

.c-faq__list {
	@include list-reset;
	@include list-stacked;
}

.c-faq__item {
	@include ui-border("bottom");
	transition: all 0.25s ease-in-out;

	&[aria-expanded="false"] {
		&:hover,
		&:focus {
			border-color: $brand;
			box-shadow: none;
		}
	}

	&[aria-expanded="true"] {
		background: $grey--lightest;
	}

	@include bp('large') {
		&[aria-expanded="true"] {
			padding-top: $bsu;
		}
	}
}

.c-faq__item-header {
	@include link-transition;
	cursor: pointer;
	position: relative;
	padding: $bsu $lsu $bsu 66px;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		color: $brand;
	}

	@include bp('large') {
		padding: $bsu $lsu $bsu 76px;
	}
}

.c-faq__item-heading {
	@include text-black(16px, 24px);

	@include bp('large') {
		@include text-black(18px, 24px);
	}
}

.c-faq__item-icon {
	@include button('small', true);
	@include btn-primary;
	position: absolute;
	top: 32px;
	left: $ssu;
	transform: translateY(-50%);

	&::before {
		@include font-icon;
		content: $iconf-plus;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		&::before {
			content: $iconf-minus;
		}
	}

	@include bp('large') {
		left: $bsu;
	}
}

.c-faq__item-body {
	display: none;
	padding: 0 $bsu $bsu 66px;

	.c-faq__item.is-expanded &, .c-faq__item[aria-expanded="true"] & {
		display: block;
	}

	@include bp('large') {
		padding: $bsu $lsu $bsu 76px;
	}
}

.c-faq__item-text {
	@include text-book(16px, 24px);
}
