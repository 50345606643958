﻿// CONTROL.HIGHLIGHTS

$highlights-bp: "medium";

.c-highlights {
	@include control;
}

.c-highlights__layout {
	@include layout-wrapper;
}

.c-highlights__heading {
	@include control__heading;
}

.c-highlights__pods {
	display: grid;
	grid-gap: $bsu;
	grid-template-columns: 1fr;

	@include bp('large') {
		grid-template-columns: 1fr 1fr;
	}
}

.c-highlights__pod {
	@include radius;
	position: relative;
	height: 100%;
	background-color: $grey--lightest;

	@include bp($highlights-bp) {
		display: flex;
	}
}

.c-highlights__content {
	padding: $bsu;

	@include bp($highlights-bp) {
		flex: 0 0 50%;
		padding: ($bsu * 2) $bsu;
	}

	.no-image & {
		padding-top: $lsu;
	}
}

.c-highlights__figure {
	@include figure;

	@include bp($highlights-bp) {
		flex: 0 0 50%;
		align-self: stretch;
	}
}

.c-highlights__image {

	@include bp($highlights-bp) {
		height: 100%;
	}
}

.c-highlights__title {
	@include text-black(20px, 28px);
	margin-bottom: 0.5em;
	color: $brand;

	@include bp($highlights-bp) {
		margin-bottom: 1.3em;
	}
}

.c-highlights__abstract {
	@include text-book(16px, 24px);
	margin-bottom: 1.5em;

	@include bp($highlights-bp) {
		margin-bottom: 3em;
	}

	@include bp("large") {
		margin-bottom: 2em;
	}
}

.c-highlights__link {
	@include text-black(16px, 28px);
}

.c-highlights__link-icon {
	position: relative;
	top: 2px;
}

.c-highlights__tag {
	@include text-black(10px, 1.6, null, 4%);
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	padding: $tsu $ssu;
	color: $white;
	background: $brand;
}
