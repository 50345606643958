// COMPONENT.HEADER

@use "sass:math";

$header-bp: "large";

$header-height: var(--header-height);

:root {
	--header-height: 80px;
}

@include bp('large') {
	:root {
		--header-height: 100px;
	}
}

.c-header {
	position: fixed;
	z-index: $z-header;
	height: var(--header-height);
	width: 100%;
	background-color: $white;
	box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	transition: all 0.25s ease-in-out;
	border-bottom: 1px solid $grey--lighter;
}

.c-header__spacer {
	height: $header-height;
	height: var(--header-height);
}

.c-header__wrapper {
	@include flex-extend;
	align-items: end;
	justify-content: space-between;

	@include bp("large") {
		align-items: center;
	}
}

.c-header__logo {
	display: inline-block;
	padding: 35px 25px 15px;
	margin-left: 0px;
	text-align: center;
	height: $header-height;
	height: var(--header-height);
	width: 100%;

	@include bp("large") {
		width: auto;
		padding: 25px;
	}

	@include bp(1200px) {
		margin-left: 15px;
	}

	img {
		height: 100%;
		object-fit: cover;
		object-position: center center;
		margin: 0 auto;

		@include bp("large") {
			width: auto;
			margin: 0;
		}
	}

	&:focus {
		@include focus-outline;
	}

	.c-header__logo-desktop {
		display: none;

		@include bp($header-bp) {
			display: block;
		}
	}

	.c-header__logo-mobile {
		display: block;

		@include bp($header-bp) {
			display: none;
		}
	}
}

.c-header__tools {
	@include flex-extend;
	flex: 1 0 auto;
	align-items: center;
	justify-content: flex-end;
	order: 3;
	padding-right: $bsu;
	margin-bottom: 10px;

	@include bp("large") {
		margin-bottom: 0px;
	}
}

// Secondary Nav
.c-header__secondary-nav {
	display: none;

	@include bp($header-bp) {
		@include list-reset;
		display: flex;
		margin-right: $ssu;
	}

	@include bp(1200px) {
		margin-right: $bsu;
	}
}

.c-header__secondary-nav-item {
	padding-left: 0;

	@include bp($header-bp) {
		padding-left: $ssu;
	}

	@include bp(1200px) {
		padding-left: $bsu;
	}
}

.c-header__secondary-nav-link {
	@include button("small");
	@include btn-primary;
}

// Language
.c-header__language {
	display: none;

	@include bp($header-bp) {
		display: block;
		padding-right: $ssu;
	}

	@include bp(1200px) {
		margin-right: $bsu;
	}

	a {
		@include text-book(14px, 22px);
		color: $tropical-rain-forest;
		@include link-transition;

		&:hover {
			font-weight: bold;
		}
	}
}

.c-header__language-dropdown {
	@include text-book(16px, 24px);
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}

// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $tropical-rain-forest;
$mobile-button--hover-color: darken($brand, 10);
$mobile-button-font-size: 22px;
$mobile-button-padding: math.div(($mobile-button-size - $mobile-button-font-size), 2);

.c-header__mobile-buttons {
	text-align: right;
	display: block;

	@include bp(1400px) {
		display: none;
	}
}

.c-header__desktop-buttons {
	display: none;

	@include bp(1400px) {
		display: block;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle

.c-header__mobile-nav {
	margin-left: 20px;
	margin-bottom: 10px;
}

.c-header__nav-toggle {
	@include button-reset;

	@include bp($header-bp) {
		display: none;
	}

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 2px;
		background: $mobile-button-color;
		border-radius: 20px;

		&:nth-child(1) {
			top: $mobile-button-padding;
		}

		&:nth-child(2) {
			top: $mobile-button-padding + 8px;
		}

		&:nth-child(3) {
			top: $mobile-button-padding + 16px;
		}
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) {
				transform: translateY(8px) rotate(45deg);
				width: 18px;
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-8px) rotate(-45deg);
				width: 18px;
			}
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}

// Search Toggle
.c-header__search-toggle {
	@include button-reset;
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;

	&[aria-expanded="true"] .iconf-search {
		&:before {
			content: $iconf-close;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search {
	display: none;
	position: absolute;
	background: $white;
	/*top: $header-height;*/
	top: var(--header-height);
	left: 0;
	width: 100%;
	height: var(--header-height);
	/*height: calc(100vh - #{$header-height});*/
	/*height: calc(100vh - var(--header-height));*/
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);

	@include bp($header-bp) {
		position: absolute;
		left: 0;
		top: $header-height;
		top: var(--header-height);
		height: auto;
	}
}

.c-header__search-wrapper {
	width: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/*padding-bottom: 20px;*/

	@include bp(600px) {
		width: 65%;
	}

	@include bp($header-bp) {
		width: 500px;
		position: static;
		padding: 50px 0 80px;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
	}
}

.c-header__search-field {
	position: relative;
}

.c-header__search-input {
	@include text-book(16px, 24px);
	@include placeholder-text($grey);
	display: block;
	padding: 6px 30px;
	width: 100%;
	margin: 0 auto;
	border: none;
	border: 1px solid $grey--light;
	border-radius: 50px;
	background: transparent;

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}

	&:focus,
	&:active {
		outline: none;
		border-color: $aquamarine;
	}
}

.c-header__search-btn {
	@include button-reset;
	position: absolute;
	padding: 0;
	width: 50px;
	height: 50px;
	right: 0;
	top: -7px;
	font-size: 20px;
	line-height: 1;
	color: $tropical-rain-forest;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include link-transition;

	&:hover,
	&:focus {
		color: darken($brand, 10%);
	}
}

.c-header__log-in {
	@include bp-below($header-bp) {
		display: none;
	}
}
