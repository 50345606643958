// CONTROL.TESTIMONIALS-ROTATOR

$testimonials-rotator-bp: "large";

.c-testimonials-rotator {
	@include control;
	@include flickity-buttons;

	.flickity-button {
		bottom: 0;
		top: auto;

		@include bp($testimonials-rotator-bp) {
			bottom: auto;
			top: 50%;
		}
	}

	.previous {
		left: $bsu;
	}

	.next {
		right: $bsu;
	}
}

.c-testimonials-rotator__layout {
	@include layout-wrapper;
}

.c-testimonials-rotator__heading {
	@include control__heading;
}

.c-testimonials-rotator__viewport {
	min-height: 560px;
	overflow: hidden;
}

.c-testimonials-rotator__item {
	width: 100%;
	position: relative;
}

.c-testimonials-rotator__figure {
	@include figure;
	min-height: 560px;
}

.c-testimonials-rotator__image {
	min-height: 560px;
}

.c-testimonials-rotator__image--mobile {
	@include bp($testimonials-rotator-bp) {
		display: none;
	}
}

.c-testimonials-rotator__image--desktop {
	@include bp-below($testimonials-rotator-bp) {
		display: none;
	}
}

.c-testimonials-rotator__spacer {
	min-height: 560px;
	background: $grey--dark;
}

.c-testimonials-rotator__body {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($black, 0.5);
	color: $white;
}

.c-testimonials-rotator__body-content {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	padding: $bsu;

	@include bp($testimonials-rotator-bp) {
		max-width: 60%;
		padding: 0 100px;
	}
}

.c-testimonials-rotator__abstract {
	display: block;
	@include text-heavy(22px, 28px);

	> p:last-child {
		margin-bottom: 0;
	}

	@include bp("medium") {
		@include text-heavy(24px, 30px);
	}

	@include bp($testimonials-rotator-bp) {
		@include text-heavy(28px, 34px);
	}
}

.c-testimonials-rotator__author {
	@include text-book(14px, 22px, italic);
	display: block;
	margin-top: $bsu;

	@include bp("medium") {
		@include text-heavy(16px, 28px, italic);
	}
}

.c-testimonials-rotator__btn {
	@include button("small");
	@include btn-primary;
	margin-top: $bsu;

	@include bp($testimonials-rotator-bp) {
		margin-top: $lsu;
	}
}
