﻿// theme

$white: #fff;

$grey--lightest: #F8F7EF;
$grey--much-lighter: #F5F4F2;
$grey--lighter: #EBEAE6;
$grey--light: #CDCCC9;
$grey: #979694;
$grey--dark: #646462;
$grey--darker: #434342;
$grey--much-darker: #323231;
$grey--darkest: #171700;

$black: #000;

$red: #e73431;
$red--dark: #780c15;

$teal: #00B2A5;
$teal25: #BFECE8;
$teal50: #80D8D2;

$aquamarine: #6BECCC;
$tropical-rain-forest: #006462;

$midnight-green: #015969;
$rich-black: #003B37;

$brand: $teal;
$brand--alt: darken($brand, 10);

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: lighten($error, 40);

// Assign colours to common variables
$body-color: $grey--darkest;
$base-ui-color: $grey--light;
$maps-background: #e5e3df;

// Main Colours
$primary-a: $brand !default;
$primary-b: $white !default;
$secondary-a: $brand--alt !default;
$secondary-b: $white !default;

$link: $brand !default;
$link-hover: $brand--alt !default;
