﻿.c-pathway-carousel {
	@include carousel-container;
	@include carousel-container-buttons;
}

.c-pathway-carousel__heading {
	@include control__heading;
	display: inline-block;
}

.c-pathway-carousel__link {
	@include carousel-container-link;
}

.c-pathway-carousel__item-list {
	@include carousel-list;
}

.c-pathway-carousel__item {
	@include carousel-item;

	&:has(.c-pathway-carousel__item-icon-wrapper) {
/*		border-bottom: 3px solid $teal;
		height: 100%;*/

		&:hover {
			img {
				transform: none;
			}
		}
	}
}

.c-pathway-carousel__item-logo-wrapper {
	height: 100px;
	margin-bottom: $bsu;

	.c-pathway-carousel__item-logo {
		display: block;
		width: 100%;

		img {
			width: 100%;
			height: 100px;
			object-fit: contain;
		}
	}
}

.c-pathway-carousel__item-icon-wrapper {
	/*height: 100px;*/
	margin-bottom: $bsu;
	padding: 0;

	.c-pathway-carousel__item-icon {
		display: block;

		img {
			max-height: 58px;
			display: block;
		}
	}
}

.c-pathway-carousel__item-image {
	@include carousel-item-image;

	border-top: 0px none;
}

.c-pathway-carousel__item-heading {
	@include carousel-item-heading;
}

.c-pathway-carousel__item-text {
	@include carousel-item-copy;
}

.c-pathway-carousel__item-link {
	@include carousel-item-link-text;
}

.c-pathway-carousel__item-link-wrapper {
	min-height: 20px;
}

.c-pathway-carousel__item-line {
	border-bottom: 3px solid $teal;
}