﻿// CONTROL.IMAGE-FEATURE
.c-image-feature {
	@include control;
}

.c-image-feature__layout-wrapper {
	@include layout-wrapper;
}

.c-image-feature__layout {
	overflow: hidden;
	padding: 0;
	display: block;

	&--right {
		flex-flow: row-reverse;
	}

	@include bp('medium') {
		@include flex-extend;
	}

	.c-image-feature--dark & {
		background: $black;
	}
}


.c-image-feature__figure {
	@include figure;
	position: relative;
	align-self: stretch;
	margin-bottom: 0;

	&::after {
		@include triangle(bottomright, 60%);
		bottom: -1px;
		right: -1px;
		top: auot;
		background: $white;
	}

	.c-image-feature--dark &::after {
		background: $black;
	}

	@include bp('medium') {
		flex: 0 0 60%;

		&::after {
			@include triangle(bottomright, 101%);
		}

		.c-image-feature__layout--right &::after {
			@include triangle(upperleft, 101%);
			right: auto;
			left: -1px;
			top: -1px;
		}
	}
}

.c-image-feature__image {
	height: 100%;
}

.c-image-feature__body {
	padding: $lsu $bsu;
	width: 100%;
	align-self: center;

	.c-image-feature--dark & {
		background: $black;
	}

	@include bp("medium") {
		padding: 50px 40px 50px 20px;

		.c-image-feature__layout--right & {
			padding: 50px 20px 50px 40px;
		}
	}

	@include bp("large") {
		padding: 50px 80px 50px 40px;

		.c-image-feature__layout--right & {
			padding: 50px 40px 50px 80px;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__layout--straight-image {
	.c-image-feature__figure {
		flex: 0 0 50%;

		&:after {
			all: unset;
		}
	}

	.c-image-feature__body {
		padding: $lsu $bsu;

		@include bp('medium') {
			padding: $lsu;
		}

		@include bp('large') {
			padding: $hsu;
		}
	}
}

.c-image-feature__eyebrow {
	@include text-heavy(14px, 16px);
	margin-bottom: $ssu;
	display: block;

	.c-image-feature--dark & {
		color: $white;
	}
}

.c-image-feature__heading {
	@include text-black(20px, 24px);
	position: relative;
	margin-bottom: $ssu;
	color: $tropical-rain-forest;

	.c-image-feature--dark & {
		color: $teal;
	}

	@include bp("large") {
	}
}

.c-image-feature__text {
	@include body-text;
	margin-bottom: $lsu;

	.c-image-feature--dark & {
		color: $white;
	}
}

.c-image-feature__link {
	@include button("small");
	@include btn-primary;
}
