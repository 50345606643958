﻿/* Branding on the banner */
div#CybotCookiebotDialogHeader,
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
	display: none !important;
}

/* Branding on the Privacy trigger */
#CybotCookiebotDialog .CybotCookiebotFader,
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
	display: none !important;
}

/* Main container */
#CybotCookiebotDialog.CybotEdge {
	padding: $ssu !important;
	top: auto !important;
	bottom: 0 !important;
	left: 0 !important;
	transform: translate(0, 0) !important;
	width: 100% !important;
	border-radius: 0 !important;
	max-width: none !important;

	.CybotCookiebotDialogContentWrapper {
		@include bp('small') {
			flex-direction: row !important;
		}
	}
}

#CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
	align-items: flex-end !important;
	margin: 0 auto;
}

.CybotCookiebotDialogBodyBottomWrapper {
	margin-top: 0 !important;
}

#CybotCookiebotDialog * {
	font-size: 12px !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer {
	min-height: 0 !important;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotScrollContainer {
	border-bottom: 0 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
	padding: 0 !important;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
	justify-content: flex-start !important;
	margin-top: 0 !important;
}

#CybotCookiebotDialogFooter {
	width: 200px !important;
	padding-bottom: 0 !important;

	button {
		width: 100% !important;
		margin: 0 !important;
	}
}
