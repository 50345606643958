// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes

$avenir-heavy: "Avenir Heavy";
$avenir-black: "Avenir Black";
$avenir-book: "Avenir Book";

$avenir-heavy-oblique: "Avenir Heavy Oblique";
$avenir-black-oblique: "Avenir Black Oblique";
$avenir-book-oblique: "Avenir Book Oblique";

$base-font-family: $avenir-book;
$heading-font-family: $avenir-book;
$base-font-size: 16px;
$base-line-height: 24px;

$weight--light: 300;
$weight--normal: 400;
$weight--medium: 500;
$weight--semibold: 600;
$weight--bold: 700;
$weight--extrabold: 800;
$weight--black: 900;


////////////////////////
//     @font-face     //
////////////////////////

@font-face {
	font-family: "Avenir Heavy Oblique";
	src: url('/content/fonts/Avenir/AvenirPro85HeavyOblique/font.woff2') format('woff2'), url('/content/fonts/Avenir/AvenirPro85HeavyOblique/font.woff') format('woff');
}

@font-face {
	font-family: "Avenir Heavy";
	src: url('/content/fonts/Avenir/AvenirPro85Heavy/font.woff2') format('woff2'), url('/content/fonts/Avenir/AvenirPro85Heavy/font.woff') format('woff');
}

@font-face {
	font-family: "Avenir Black";
	src: url('/content/fonts/Avenir/AvenirPro95Black/font.woff2') format('woff2'), url('/content/fonts/Avenir/AvenirPro95Black/font.woff') format('woff');
}

@font-face {
	font-family: "Avenir Book";
	src: url('/content/fonts/Avenir/AvenirPro45Book/font.woff2') format('woff2'), url('/content/fonts/Avenir/AvenirPro45Book/font.woff') format('woff');
}

@font-face {
	font-family: "Avenir Black Oblique";
	src: url('/content/fonts/Avenir/AvenirPro95BlackOblique/font.woff2') format('woff2'), url('/content/fonts/Avenir/AvenirPro95BlackOblique/font.woff') format('woff');
}

@font-face {
	font-family: "Avenir Book Oblique";
	src: url('/content/fonts/Avenir/AvenirPro45BookOblique/font.woff2') format('woff2'), url('/content/fonts/Avenir/AvenirPro45BookOblique/font.woff') format('woff');
}

////////////////////////
// Base font variants //
////////////////////////

@mixin text-book($font-size: 16px, $line-height: 1.3m, $style: null, $letter-spacing: 0px) {
	font-size: $font-size;
	line-height: $line-height;
	font-family: $avenir-book;
	font-style: normal;
	letter-spacing: $letter-spacing;

	@if $style == 'italic' {
		font-family: $avenir-book-oblique;
	}
}

@mixin text-heavy($font-size: 16px, $line-height: 1.3m, $style: null, $letter-spacing: 0px) {
	font-size: $font-size;
	line-height: $line-height;
	font-family: $avenir-heavy;
	font-style: normal;
	letter-spacing: $letter-spacing;
	font-weight: normal;

	@if $style == 'italic' {
		font-family: $avenir-heavy-oblique;
	}
}

@mixin text-black($font-size: 16px, $line-height: 1.3m, $style: null, $letter-spacing: 0px) {
	font-size: $font-size;
	line-height: $line-height;
	font-family: $avenir-black;
	font-style: normal;
	letter-spacing: $letter-spacing;
	font-weight: normal;

	@if $style == 'italic' {
		font-family: $avenir-black-oblique;
	}
}

html {
	color: $body-color;
	@include text-book(16px, 24px);
}

p {
	margin-top: 0;
}

@mixin custom-text-link {
	@include link-transition;
	text-decoration: underline;
	text-decoration-color: $teal;
	color: $tropical-rain-forest;

	&:hover {
		color: $teal;
		text-decoration-color: $tropical-rain-forest;
	}
}

.custom-t-link {
	@include link-transition;
}