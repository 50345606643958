.c-home-quicklinks {
	color: $white;
	padding: 30px 0;
	container: quicklinks / inline-size;
	background: $black;
	z-index: $z-quicklinks;
	margin-bottom: 40px;

	@include bp("medium") {
		margin-bottom: 80px;
	}
}

.c-home-quicklinks__wrapper {
	@include layout-wrapper;
	display: block;

	@include bp("medium") {
		display: flex;
		align-items: center;
	}
}

.c-home-quicklinks__text {
	@include text-black(28px, 30px);
	display: inline-block;
	margin-bottom: 13px;

	@include bp("medium") {
		margin-right: $lsu;
		margin-bottom: 0;
	}
}

.c-home-quicklinks__container {
	position: relative;
	width: 100%;
	max-width: 500px;
}

.c-home-quicklinks__items {
	border: 0;
	background: transparent;
	color: $white;
	@include text-book(24px, 30px);
	padding: $ssu 0;
	border-bottom: 1px $grey--darker solid;
	width: 100%;
	transition: all 0.3s;

	&:hover {
		border-bottom: 1px $teal solid;
		cursor: pointer;
	}

	&::placeholder {
		color: $grey--light;
	}
}

.c-home-quicklinks__item {
	color: $black;
	padding: $tsu;
}

@container quicklinks (min-width: 743px) {
	.c-home-quicklinks__text {
		margin-bottom: 0;
	}

	.c-home-quicklinks__items {
		max-width: 524px;
	}
}

.c-home-heading {
	@include text-black(22px, 24px);
	border-left: 5px solid $teal;
	padding-left: 30px;
	display: block;

	@include bp("medium") {
		@include text-black(38px, 40px);
		border-left: 5px solid $teal;
		padding-left: 30px;
	}

	strong {
		color: $teal;
	}
}

.c-home-quicklinks__container .select {
	position: relative;

	&:after {
		position: absolute;
		content: "";
		width: 15px;
		height: 15px;
		top: 50%;
		right: 15px;
		transform: translateY(-50%) rotate(45deg);
		border-bottom: 2px solid white;
		border-right: 2px solid white;
		cursor: pointer;
		transition: border-color 0.4s;
	}

	&:hover {
		&:after {
			border-color: $teal;
		}
	}
}

.c-home-quicklinks__container.active .select::after {
	border: none;
	border-left: 2px solid white;
	border-top: 2px solid white;
}

.c-home-quicklinks__container .option-container {
	position: absolute;
	width: 100%;
	background: $white;
	max-height: 0;
	overflow-y: auto;
	transition: 0.4s;
	border: 1px solid $grey;
}

.c-home-quicklinks__container.active .option-container {
	max-height: 250px;
}

.c-home-quicklinks__container .option-container::-webkit-scrollbar {
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	width: 10px;
}

.c-home-quicklinks__container .option-container::-webkit-scrollbar-thumb {
	background: #0f0e11;
}

.c-home-quicklinks__container .option-container .option {
	position: relative;
	padding-left: 15px;
	height: 60px;
	border-top: 1px solid rgba(0, 0, 0, 0.3);
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: 0.2s;
}

.c-home-quicklinks__container .option-container .option.selected {
	background: rgba(0, 0, 0, 0.5);
	pointer-events: none;
}

.c-home-quicklinks__container .option-container .option:hover {
	background: $teal25;
	padding-left: 20px;
}

.c-home-quicklinks__container .option-container .option label {
	font-size: 1.1rem;
	color: $grey--darkest;
	cursor: pointer;
}
