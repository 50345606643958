// CONTROL.LISTING-TILED

.c-listing-tiled {
	margin: $bsu 0;
}

.c-listing-tiled__item {
}

.c-listing-tiled__item-link {
	@include ui-border("bottom");
	@include link-transition(border-color, background);
	display: block;
	position: relative;
	height: 100%;

	&:hover,
	&:focus {
		border-color: $brand;
		box-shadow: none;
		border-color: $teal;
		background: $grey--lightest;

		&:not(:focus-visible) {
			outline: none;
		}

		.c-listing-tiled__item-heading {
			color: $teal;
		}
	}
}

.c-listing-tiled__item-heading {
	@include text-heavy(18px, 24px);
	margin-bottom: $ssu;
	color: $body-color;
}

.c-listing-tiled__item-figure {}

.c-listing-tiled__item-meta {
	display: block;
	margin-bottom: $ssu;
	@include text-heavy(12px, 18px);
	color: $teal;
}

.c-listing-tiled__item-body {
	@include text-book(16px, 28px);
	color: $body-color;
	margin-bottom: 0;
	padding: $bsu $ssu;

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-listing-tiled__item-category {
	@include carousel-item-category;
}

.c-listing-tiled__item-date {
	@include carousel-item-date;
	display: block;
	margin-bottom: $ssu;
}

c-listing-tiled__item-tick {
	position: absolute;
	right: $bsu;
	top: $bsu;
}