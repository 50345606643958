﻿// CONTROL.IMAGE-CTA

@use "sass:math";

$image-cta-bp: "large";

.c-image-cta {
	@include control;
}

.c-image-cta__layout {
	@include layout-wrapper;
}

.c-image-cta__viewport {
	height: 0;
	padding-bottom: 100%;
	overflow: hidden;
	position: relative;

	@include bp('medium') {
		padding-bottom: 56.25%;
	}

	@include bp($image-cta-bp) {
		padding-bottom: percentage(math.div(540, 1366)); //desktop use hero crop for now
	}
}

.c-image-cta__item {
	width: 100%;
	position: absolute;
	height: 100%;
}

.c-image-cta__figure {
	@include figure;
	height: 100%;

	.o-fit {
		height: 100%;
	}
}

.c-image-cta__image {
}

.c-image-cta__image--mobile {
	@include bp($image-cta-bp) {
		display: none;
	}
}

.c-image-cta__image--desktop {
	@include bp-below($image-cta-bp) {
		display: none;
	}
}

.c-image-cta__body {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($black, 0.2);
	color: $white;
}

.c-image-cta__body-content {
	@include abs-center;
	width: 80%;
	max-width: 800px;
	text-align: center;
}

.c-image-cta__heading {
	@include text-black(20px, 24px);
	margin-bottom: $bsu;
}

.c-image-cta__abstract {
	@include text-book(18px, 28px);
}

.c-image-cta__btn {
	@include button("small");
	@include btn-primary;
	margin-top: $lsu;
}
