﻿// CONTROL.GOOGLE-MAP

@use "sass:math";

.c-google-map {
	@include control;
}

.c-google-map__layout {
	@include layout-wrapper;
}

.c-google-map__heading {
	@include control__heading;
}

.c-google-map__figure {
	aspect-ratio: 2/1;
	background-color: $maps-background; // matches Google Maps loading background colour
}
