﻿// CONTROL.CTA

.c-cta {
	@include control;
}

.c-cta__layout {
	@include layout-wrapper;
	container: cta / inline-size;

	.l-page__sidebar & {
		padding: 0;
	}
}

.c-cta--dark {
	.c-cta__body {
		background: $grey--darkest;
	}

	.c-cta__heading {
		color: $aquamarine;
	}

	.c-cta__text {
		color: $white;
	}
}

.c-cta__body {
	position: relative;
	padding: $lsu $bsu;
	margin: $bsu 0;
	text-align: center;
	background-color: $grey--lightest;


	> *:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}
}


.c-cta__heading {
	@include control__heading;
	margin-bottom: 0.5em;
	color: $tropical-rain-forest;

	&:after {
		display: block;
		content: '';
		width: 90px;
		height: 1px;
		margin: $bsu auto;
		background: $grey;
	}
}

.c-cta__text {
	@include text-book(16px, 24px);
	color: $body-color;
	flex: 1;
}


.c-cta__link {
	@include button("medium");
	@include btn-primary;

	&__icon {
		margin-left: $tsu;
	}
}

@container cta (min-width: 750px) {

	.c-cta__body {
		@include flex-extend;
		padding: ($bsu * 1.5) ($bsu * 2);
		align-items: center;
		justify-content: center;
		text-align: left;
		gap: 60px;
	}

	.c-cta__heading {
		@include text-heavy(28px, 30px);
		text-align: right;
		position: relative;
		padding-right: 60px;
		margin-bottom: 0;

		&:after {
			@include abs-center-v;
			width: 1px;
			right: 0;
			height: 50px;
			background: $grey;
			margin: 0;
		}
	}

	.c-cta__body:has(.c-cta__text) {
		.c-cta__heading {
			flex: 0 0 30%;
		}
	}

	.c-cta__text {
		margin-bottom: 0;
	}

	.c-cta__link {
		white-space: nowrap;
	}
}
