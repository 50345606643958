/*.c-people-listing {
	
}*/

/* Filter */

.c-people-filter {
	background: $midnight-green;
	padding: 30px 0;
}

.c-people-filter__container {
	@include layout-wrapper;
}

.c-people-filter__wrapper {
	display: grid;
	gap: 20px;
	align-items: end;
	margin: 0 0 20px 0;
	grid-template-columns: 1fr;

	&[aria-hidden=true] {
		display: none;

		@include bp("medium") {
			display: grid;
			gap: 20px;
			align-items: end;
			margin: 0;
		}
	}

	@include bp("small") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include bp("medium") {
		grid-template-columns: repeat(4, 1fr);
	}
}

.c-people-filter__expander-button-open {

	&[aria-expanded=true] {
		display: none;
	}

	&[aria-expanded=false] {
		display: flex;
		margin: 0 auto;
		background: none;
		border: 0;
		color: $white;

		@include bp("medium") {
			display: none;
		}
	}

	span {
		margin-left: 10px;
		border-left: 1px solid rgba(255, 255, 255, 0.20);
		padding-left: 5px;
	}
}

.c-people-filter__expander-button-close {

	&[aria-expanded=true] {
		display: flex;
		margin: 0 auto;
		background: none;
		border: 0;
		color: $white;

		@include bp("medium") {
			display: none;
		}
	}

	&[aria-expanded=false] {
		display: none;
	}

	span {
		margin-left: 10px;
		border-left: 1px solid rgba(255, 255, 255, 0.20);
		padding-left: 5px;
	}
}

.c-people-filter__option {
	position: relative;

	input, select {
		line-height: 26px !important;
	}

	label {
		display: block;
		width: 100%;
		color: $white;
		margin-bottom: 5px;
	}

	.c-people-filter__option-search-icon {
		position: absolute;
		right: 2px;
		bottom: 2px;
		height: 36px;
		width: 40px;
		padding: 6px;
		font-size: 25px;
		color: $teal;
		background: white;
	}
}

.c-people-filter__button {
	@include button("medium");
	@include btn-primary;
	@include text-heavy(14px, 20px);

	@include bp("medium") {
		margin-top: 30px;
	}
}

/* Summary */
.c-filter-summary {
	@include layout-wrapper;
	padding-top: 40px;
}

.c-filter-summary__filter-summary {
	margin-bottom: 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.c-filter-summary__heading {
	@include text-heavy(16px, 24px);
	margin: 0 8px 5px 0;
}

.c-filter-summary__filter-buttons {
	@include button("smallest");
	@include label-secondary;
	margin: 0 8px 8px 0;
	white-space: normal;

	span {
		font-size: 16px;
		border-left: 1px solid rgba($black, 0.10);
		margin-left: 8px;
	}
}

.c-filter-summary__reset {
	@include button("smallest");
	@include label-primary;
	margin-bottom: 8px;

	span {
		font-size: 16px;
		margin-right: 4px;
	}
}

.c-filter-summary__count {
	height: 25px;
}

.filters-enter-active,
.filters-leave-active {
	opacity: 1;
	transition: opacity .3s;
}

.filters-enter,
.filters-leave-to {
	opacity: 0;
}

.vue-people-list-control {
	@include layout-wrapper;
	@include control;

	h2 {
		@include text-black(28px, 30px);
	}
}

/* Listing */
.c-people-list {
	@include layout-wrapper;
	gap: 20px;
	padding-top: 40px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include bp("medium") {
		grid-template-columns: repeat(3, 1fr);
	}

	@include bp("large") {
		grid-template-columns: repeat(4, 1fr);
	}

	@include bp("extra-large") {
		grid-template-columns: repeat(5, 1fr);
	}

	.c-people-list__item {
		@include hover-bounce;
		color: $black;
		background: $white;

		&:hover {
			background: $grey--lightest;
			box-shadow: 0px 8px 12px 2px rgba($black, 0.2);

			.c-people-list__item-heading {
				color: $tropical-rain-forest;
			}

			.c-people-list__item-image {
				transform: scale(1.1);
				transition: all 0.3s;
			}
		}
	}


	.c-people-list__item-image-wrapper {
		height: auto;
		width: 100%;
		overflow: hidden;
		line-height: 0px;

		.c-people-list__item-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
			transition: all 0.3s;
		}
	}

	.c-people-list__item-wrapper {
		padding: 10px;

		.c-people-list__item-heading {
			@include text-black(14px, 16px);

			@include bp("medium") {
				@include text-black(16px, 24px);
			}
		}

		.c-people-list__item-role {
			@include text-book(13px, 16px);

			@include bp("medium") {
				@include text-book(16px, 24px);
			}
		}

		.c-people-list__item-title {
			@include text-heavy(13px, 16px);
		}
	}
}

.l-page__sidebar {
	.c-people-list {
		@include layout-wrapper;
		display: block;
		gap: 20px;
		padding: 40px 0 0;

		.c-people-list__item {
			@include hover-bounce(0);
			color: $black;
			background: $white;
			display: flex;
			margin-bottom: 20px;

			&:hover {
				background: $grey--lightest;
				box-shadow: 0px 4px 8px 0px rgba($black, 0.15);

				.c-people-list__item-heading {
					color: $tropical-rain-forest;
				}
			}
		}

		.c-people-list__item-image-wrapper {
			height: 80px;
			width: 80px;
			overflow: hidden;

			.c-people-list__item-image {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.c-people-list__item-wrapper {
			padding: 8px 10px;
			width: calc(100% - 80px);
		}
	}
}


/* Listing Small */
.list-enter-from {
	opacity: 0;
	transform: scale(0);
}

.list-enter-to {
	opacity: 1;
	transform: scale(1);
}

.list-enter-active,
.list-leave-active,
.list-move {
	transition: all 0.7s;
}

.list-leave-active {
	transform: scale(1);
	opacity: 0;
}

.list-leave-from {
	opacity: 1;
	transform: scale(1);
}

.list-leave-to {
	opacity: 0;
	transform: scale(0);
}


/* Listing Small */
/*.list-enter-active {
	transition: all 0.3s ease-out;
}

.list-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.list-enter-from,
.list-leave-to {
	transform: translateX(20px);
	opacity: 0;
}
*/
