﻿.c-two-column {
	@include control;
}

.c-two-column__layout-wrapper {
	@include layout-wrapper;
}

.c-two-column__layout {
	@include bp("large") {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: $hsu;
		grid-column-gap: var(--control-gap);
	}
}

.c-two-column__heading {
	@include control__heading;
}

.c-two-column__column {

	> *:first-child {
		margin-top: 0;
	}

	&:last-child {
		> *:last-child {
			margin-bottom: 0;
		}
	}

	@include bp("large") {
		> *:last-child {
			margin-bottom: 0;
		}
	}
}
