﻿// CONTROL.VIDEO

@use "sass:math";

.c-video {
	@include control;
}

.c-video__layout {
	@include layout-wrapper;
}

.c-video__heading {
	@include control__heading;
}

.c-video__embed {
	@include embed;
	padding-bottom: percentage(math.div(9, 16));
}

.c-video__cover {
	@include figure;
	@include abs-center;
	width: 100%;
}

.c-video__icon {
	@include playbutton;
}

.c-video__figcaption {
	@include control__figcaption;
	@include text-book(14px, 24px, italic);
}
