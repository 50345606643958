﻿.c-logo-carousel {
	@include control;
}

.c-logo-carousel__layout {
	@include layout-wrapper;
}

.c-logo-carousel__heading {
	@include control__heading;
}

.c-logo-carousel__list {
	.flickity-button {
		display: none;
	}

	@include bp("medium") {
		padding-left: $lsu;
		padding-right: $lsu;

		.flickity-button {
			display: block;
		}
	}
}

.c-logo-carousel__item {
	display: inline-block;
	width: 150px;
	padding: 0 $bsu;

	&:nth-child(n+4) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}

	@include bp("medium") {
		width: 33%;
		float: left;

		&:nth-child(n+2) {
			display: inline-block;
		}

		&:nth-child(n+4) {
			display: none;
		}
	}

	@include bp("large") {
		width: 16.6667%;

		&:nth-child(n+4) {
			display: inline-block;
		}

		&:nth-child(n+7) {
			display: none;
		}
	}
}

.c-logo-carousel__image {
	img {
		display: block;
		max-width: 100%;
	}
}
