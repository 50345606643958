.c-expert {
}

.c-expert__filter-wrapper {
	padding: 30px 0;
	background: $tropical-rain-forest;
}

.c-expert__filter {
	@include layout-wrapper;

	@include bp("medium") {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
}

.c-expert__filter__option {
	width: 100%;
	position: relative;

	@include bp("medium") {
		width: auto;
	}

	label {
		@include text-heavy(16px, 24px);
		color: $aquamarine;
		margin-right: 20px;
	}
}

.c-expert__filter-searchbox {
	width: 100% !important;
	margin: 0 0 20px 0;

	@include bp("medium") {
		width: 320px !important;
		margin: 0 30px 0 0;
	}

	@include bp("large") {
		width: 320px !important;
		margin: 0 70px 0 0;
	}
}

.c-expert__filter__option-search-icon {
	position: absolute;
	right: 2px;
	bottom: 2px;
	top: 2px;
	height: 42px;
	width: 40px;
	padding: 8px;
	font-size: 25px;
	color: $teal;
	background: white;

	@include bp("medium") {
		right: 50px;
	}

	@include bp("large") {
		right: 88px;
	}
}

.c-expert__filter-button {
	@include button("small");
	@include btn-primary;
	margin-right: 8px;

	&.is-selected {
		background: $black;
		border-color: $black;
		color: $white;
	}
}

/* Header */

.c-expert__list-header {
	@include text-black(28px, 30px);
	margin-bottom: 20px;
}

/* List */
.c-expert__list-wrapper {
	@include control;
}

.c-expert__list-container {
	@include layout-wrapper;
}

.c-expert__list {
	@include list-reset;
	padding: $ssu 0;
	margin-bottom: $ssu;
	column-count: 1;
	column-gap: 20px;
	column-width: auto;
	display: block;
	flex-grow: 1;

	@include bp("medium") {
		column-count: 2;
	}


}

.c-expert__list-item {
	@include link-transition;
	@include text-heavy(16px, 24px);
	border-bottom: 1px solid $grey--light;
	color: $black;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;

	-webkit-column-break-inside: avoid;
	column-break-inside: avoid;

	a {
		color: inherit;
		display: block;
		width: 100%;
	}

	@include bp("medium") {
		@include text-heavy(18px, 24px);
	}

	.c-expert__list-item-icon {
		@include button('small', true);
		@include btn-primary;
		opacity: 0;
		transition: opacity 0.3s;

		span {
			color: $tropical-rain-forest;
			font-size: 15px;
		}
	}

	&:hover {
		color: $midnight-green;
		border-bottom: 1px solid $tropical-rain-forest;
		padding-left: 20px;

		.c-expert__list-item-icon {
			opacity: 1;
		}
	}
}
