﻿// control.locations-list

.c-locations-list {
	@include control;
}

.c-locations-list__layout {
	@include layout-wrapper;
}

.c-locations-list__heading {
	@include control__heading;
}

.c-locations-list__list {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: $lsu;
	container: location / inline-size;
}

.c-locations-list__list-item {
	@include radius;
	display: block;
}

.c-locations-list__body {
	background: $grey--lightest;
	padding: $bsu;
}

.c-locations-list__location-heading {
	@include text-heavy(18px, 24px);
	margin-bottom: $ssu;
}

.c-locations-list__details {
}

.c-locations-list__details-item {
	@include flex-extend;
	padding-bottom: $ssu;

	&:last-child {
		padding-bottom: 0;

		a {
			@include link-transition;
			text-decoration: underline;
			text-decoration-color: $teal;
			color: $tropical-rain-forest;

			&:hover {
				color: $teal;
				text-decoration-color: $tropical-rain-forest;
			}
		}
	}
}

.c-locations-list__location-text {
	margin-bottom: $ssu;
}

.c-locations-list__details-icon {
	display: inline-flex;
	width: 20px;
	padding-top: 4px;
	margin-right: $ssu;
	font-size: 18px;
	text-align: center;
}

.c-locations-list__details-text {
	@include text-heavy(16px, 24px);

	a {
		word-break: break-word;
		color: $body-color;
	}

	> p:last-child {
		margin-bottom: 0;
	}
}

.c-locations-list__map {
	aspect-ratio: 2/1;
	background-color: $maps-background; // matches Google Maps loading background colour
	min-height: 340px;
}

@container location (min-width: 900px) {
	.c-locations-list__location-heading {
		@include text-heavy(24px, 28px);
		margin-bottom: $bsu;
	}

	.c-locations-list__location-text {
		margin-bottom: $bsu;
	}

	.c-locations-list__body {
		padding: $lsu;
	}

	.c-locations-list__list-item {
		display: flex;
	}

	.c-locations-list__body {
		width: 49%;
	}

	.c-locations-list__map-container {
		order: 2;
		width: 51%;
	}

	.c-locations-list__map {
		aspect-ratio: auto;
		height: 100%;
	}
}



@container location (min-width: 1200px) {
	.c-locations-list__body {
		padding: $lsu $hsu;
	}
}
