.c-insight-news {
}

.c-insight-news__tabs {
	background: $midnight-green;
	margin-bottom: 35px;
}

.c-insight-news__tabs-wrapper {
	@include layout-wrapper;
	display: flex;
}

.c-insight-news__tabs-item {
	width: 50%;
	padding: 30px 0;
	text-align: center;
	position: relative;
	@include text-heavy(18px, 24px);
	color: $white;
	transition: all 0.3s;

	&[aria-current=true] {
		background: $white;
		color: $midnight-green;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 3px;
			background: linear-gradient(270deg, #55EFC4 18.6%, #00B2A5 76.86%);
		}
	}

	&:hover {
		color: $teal;
		transition: all 0.3s;
	}
}

/* Insights & News*/

.c-insight-news__filter {
	border-bottom: 1px solid $grey--light;
	padding-bottom: 30px;
	margin-bottom: 50px;
}

.c-insight-news__filter-wrapper,
.c-insight-filter,
.c-news-filter,
.c-insight-news-wrapper {
	@include layout-wrapper;
}

.c-insight-list,
.c-news-list {
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: repeat(4);

	@include bp("small") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include bp("medium") {
		grid-template-columns: repeat(3, 1fr);
	}

	@include bp("large") {
		grid-template-columns: repeat(4, 1fr);
	}
}

/* Insights Filter */

.c-insight-filter {
	display: grid;
	gap: 20px;
	align-items: end;
	margin: 0 0 20px 0;
	grid-template-columns: 1fr;

	@include bp("small") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include bp("medium") {
		grid-template-columns: repeat(3, 1fr);
	}
}

.c-insight-filter__button {
	@include button("medium");
	@include btn-primary;
	@include text-heavy(14px, 20px);
}

/* News Filter */

.c-news-filter__option {
	width: fit-content;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	label {
		@include text-heavy(16px, 24px);
		margin: 0 30px 10px 0;
	}

	button {
		@include button("small");
		@include btn-outline;
		@include text-heavy(14px, 20px);
		margin: 0 10px 10px 0;

		&.is-selected {
			background: $black;
			border-color: $black;
			color: $white;
		}
	}
}

/* Summary */

.c-insight-news__summary {
	margin-bottom: 50px;
}

/* Latest insights container */

.c-latest-insights {
	@include carousel-container;
	@include carousel-container-buttons;
	background: $grey--lightest;
	padding: 60px 0;
}

.vue-article-list-control {
	&:last-child {
		margin-bottom: -40px;

		@include bp("large") {
			margin-bottom: -80px;
		}
	}
}

.c-latest-insights__heading {
	@include control__heading;
	display: inline-block;
}

.c-latest-insights__link {
	@include carousel-container-link;
}

.c-latest-insights__item-list {
	@include carousel-list;
}

/* Article Item */
.c-article-list__item {
	@include carousel-item;
}

.c-article-list__item-image {
	@include carousel-item-image;
}

.c-article-list__item-category {
	@include carousel-item-category;
}

.c-article-list__item-heading {
	@include carousel-item-heading;
}

.c-article-list__item-date {
	@include carousel-item-date;
}

.c-article-list__item-body {
	@include carousel-item-copy;
}

.c-article-list__item-link-text {
	@include carousel-item-link-text;
}
