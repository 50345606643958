﻿// CONTROL.SUBNAV

$selected-accent-width: 5px;

.c-subnav__heading {
	@include text-heavy(12px, 16px);
	display: block;
	margin-bottom: $bsu;
	color: $body-color;
}

.c-subnav__items {
	@include list-reset;
	@include text-heavy(16px, 24px);
	border-left: 1px $grey solid;
}

.c-subnav__item {

	&[aria-current]:not([aria-current="false"]) {
		position: relative;

		&::before {
			@include pseudo-element;
			left: 0;
			top: 0;
			width: $selected-accent-width;
			height: 100%;
		}
	}
}

.c-subnav__link,
.c-subnav__child-link {
	@include link-transition;
	color: $body-color;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-subnav__link {
	display: block;
	padding: $ssu $bsu;
	padding-left: $bsu + $selected-accent-width;

	[aria-current]:not([aria-current="false"]) > & {
		color: $brand;
	}
}

.c-subnav__children {
	@include list-reset;

	padding: $ssu $bsu;
	padding-left: ($bsu * 1.5) + $selected-accent-width;
}

.c-subnav__child {
	margin-bottom: 0.75em;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-subnav__child-link {
	color: $body-color;
}