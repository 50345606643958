// CONTROL.LISTING

.c-listing {
	@include control;
}

.c-listing__layout {
	@include layout-wrapper;
}

.c-listing__heading {
	@include control__heading;
}

.c-listing__no-results {
	display: block;
	margin-top: $bsu;

	@include text-book(20px, 28px);
}
