﻿// CONTROL.PODS

$pod-bp: "large";

.c-pods {
	@include control;
}

.c-pods__layout {
	@include layout-wrapper;
}

.c-pods__heading {
	@include control__heading;
}

.c-pod,
.c-pod-noimage {
	position: relative;
	display: block;
	height: 285px;
}

.c-pod-noimage {
	background: $brand;
	@include hover-bounce;
}

.c-pod__text,
.c-pod-noimage__text {
	opacity: 0;
	transition: opacity 0.3s 0.1s;
}

.c-pod__body {
	transition: all 0.3s ease-in-out;
}

.c-pod__body,
.c-pod-noimage__body {
	position: absolute;
	left: 0;
	bottom: 0;
	top: calc(100% - 85px);
	width: 100%;
	max-height: 85px;
	transition: all 0.3s ease-in-out;
	padding: $bsu;
	color: $white;
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.c-pod__heading,
.c-pod-noimage__heading {
	@include text-heavy(18px, 28px);

	@include bp('large') {
		@include text-heavy(20px, 28px);
	}
}

.c-pod-noimage,
.c-pod__figure {
	@include figure;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: translateY(0px);
	transition: all 0.25s ease-in-out;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, #000000 0%, transparent 100%);
		z-index: 0;
	}

	&:hover,
	&:focus {
		@include hover-bounce;
		box-shadow: 0px 8px 12px 2px rgba(0, 0, 0, 0.2);

		&:after {
			background: rgba($black, 0.5);
		}

		.c-pod__heading,
		.c-pod-noimage__heading {
			top: $lsu + $bsu;
			margin-top: $bsu;
			margin-bottom: $ssu;
			position: static;
			transform: translateY(0);
			transition: top 0.3s, position 0.3s 0.5s;
		}

		.c-pod__body,
		.c-pod-noimage__body {
			max-height: 500px;
			height: 100%;
			top: 0;
			transition: all 0.3s ease-in-out;
		}

		.c-pod__text,
		.c-pod-noimage__text {
			opacity: 1;
		}
	}
}

.c-pod__image {
	position: absolute;
	left: 50%;
	top: 50%;
	width: auto;
	min-width: 101%;
	min-height: 101%;
	transform: translate(-50%, -50%);
	// Override o-fit styles
	height: 100%;
	padding-bottom: 0 !important;
}

.c-pod__image--mobile {
	@include bp($pod-bp) {
		display: none;
	}
}

.c-pod__image--desktop {
	@include bp-below($pod-bp) {
		display: none;
	}
}
