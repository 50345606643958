// COMPONENT.PAGINATION

@use "sass:math";

$pagination-bp: "medium";

.c-pagination {
	/*	@include flex-extend;
	@include text-book(16px, 24px);
	justify-content: center;
	align-items: center;
	margin-top: $bsu;
	margin-bottom: $bsu;
	text-align: center;

	@include bp("large") {
		margin-top: $lsu;
		margin-bottom: $lsu;
	}*/
}

/*.c-pagination__desktop {*/
/*flex-grow: 1;

	@include grid;*/
/*
	@include bp-below($pagination-bp) {
		display: none;
	}*/
/*}*/

.c-pagination__summary {
	padding-left: $bsu;
	width: percentage(math.div(1, 3));
}

.c-pagination__body {
	/*padding-left: $bsu;*/
	/*width: percentage(math.div(2, 3));*/
	/*text-align: right;*/
}

.c-pagination__list {
	@include list-reset;
	@include list-inline;
}

/*.c-pagination__mobile {
	text-align: center;
	@include bp($pagination-bp) {
		display: none;
	}
}*/

.c-pagination__current {
	/*	padding: 0 $bsu;

	@include bp($pagination-bp) {
		padding: 0 $lsu;
	}*/
}

.c-pagination__dropdown {
	padding: $ssu;
	margin: 0 $tsu;
}

// "&, a" because the class is sometimes on an <li> and sometimes on an <a>
.c-pagination__prev,
.c-pagination__number,
.c-pagination__next {
	/*text-align: center;*/
	/*&, a {
		@include link-transition;
		@include text-heavy(18px, 28px);
		padding: 0 $tsu;
		white-space: nowrap;
	}

	&[aria-current]:not([aria-current="false"]) a {
		text-decoration: underline;
	}

	&:first-child {
		&, a {
			padding-left: 0;
		}
	}

	&:last-child {
		&, a {
			padding-right: 0;
		}
	}*/
}

.c-pagination__nav-text {
	/*display: none;*/
	/*
	@include bp("small") {
		display: inline;
	}*/
}



/* Pagination */

.pagi {
	@include text-heavy(14px, 24px);
	margin: 0 auto;
	width: fit-content;
	display: flex;
	align-items: center;
	padding-top: 40px;

	.pagi-button {
		background: none;
		border: 0px none;
		display: flex;
		align-items: center;
		border: 1px solid transparent;
		background: transparent;
		padding: 7px;
		border-radius: 50px;
		transition: all 0.3s;

		@include bp("medium") {
			padding: 13px 15px;
		}

		&:not(.is-disabled),
		&:not([disabled]) {
			color: $tropical-rain-forest;
		}

		&.is-disabled,
		&[disabled] {
			cursor: default;
			pointer-events: none;
			color: $grey--light;
		}

		&:hover {
			border: 1px solid $teal;
			background: $teal25;
			cursor: pointer;
		}

		a {
			display: block;
			color: inherit;
		}

		.c-pagination__text {
			display: none;
			line-height: 1;
			margin-bottom: -2px;

			@include bp("medium") {
				display: inline-block;
			}
		}
	}

	.pagi-prev {
		margin-right: 5px;

		span {
			margin: 0;

			@include bp("medium") {
				margin: 0 0 0 5px;
			}
		}
	}

	.pagi-next {
		margin-left: 5px;

		span {
			margin: 0;

			@include bp("medium") {
				margin: 0 0 0 5px;
			}
		}
	}

	.pagi-num {
		padding: 7px;
		height: 44px;
		width: 44px;
		border-radius: 50px;
		border: 1px solid transparent;
		background: transparent;
		transition: all 0.3s;
		margin: 0 5px;
		text-align: center;
		color: $tropical-rain-forest;
		max-width: 30px;
		max-height: 30px;

		@include bp("medium") {
			max-width: 44px;
			max-height: 44px;
			padding: 10px;
		}

		a {
			display: block;
			color: inherit;
		}

		&.is-selected {
			border: 1px solid $grey--lighter;
			background: $grey--lighter;
			color: $black;
		}

		&:hover {
			border: 1px solid $teal;
			background: $teal25;
			cursor: pointer;
		}
	}

	.pagi-more {
		@include button("medium");
		@include btn-primary;
		display: block;

		&:disabled {
			display: none;
		}
	}
}
