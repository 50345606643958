// CONTROL.GALLERY-GRID

.c-gallery-grid {
	@include control;
}

.c-gallery-grid__layout {
	@include layout-wrapper;
}

.c-gallery-grid__heading {
	@include control__heading;
}

.c-gallery-grid__list {
	padding: 0;
	margin: 0;
	list-style: none;
	display: grid;
	column-gap: $ssu;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(1, 1fr);

	@include bp("small") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include bp("medium") {
		grid-template-columns: repeat(3, 1fr);
	}

	@include bp("large") {
		grid-template-columns: repeat(4, 1fr);
	}
}

@mixin hiddenGridItem {
	height: 0;
	opacity: 0;
	overflow: hidden;
	margin-bottom: 0;
	transition: opacity 1s;
}

@mixin showingGridItem {
	opacity: 1;
	height: auto;
	margin-bottom: $ssu;
}

.c-gallery-grid__list-item {
	@include hiddenGridItem;
	@include hover-bounce;

	.show-all & {
		@include showingGridItem;
	}

	&:nth-of-type(-n+2) {
		@include showingGridItem;
	}

	@include bp("small") {
		&:nth-of-type(-n+4) {
			@include showingGridItem;
		}
	}

	@include bp("medium") {
		&:nth-of-type(-n+6) {
			@include showingGridItem;
		}
	}

	@include bp("large") {
		&:nth-of-type(-n+8) {
			@include showingGridItem;
		}
	}
}

.c-gallery-grid__button {
	@include button;
	display: none;
	margin: auto;
	text-align: center;

	.show-more + & {
		display: block;
	}

	@include bp-below("large") {
		.show-more-large + & {
			display: block;
		}
	}

	@include bp-below("medium") {
		.show-more-medium + & {
			display: block;
		}
	}

	@include bp-below("small") {
		.show-more-small + & {
			display: block;
		}
	}
}