﻿// control.contact-details

@use "sass:math";

.c-contact-details {
	@include control;
}

.c-contact-details__wrapper {
	@include layout-wrapper;
}

.c-contact-details__heading {
	@include control__heading;
	grid-column: 1 / 3;
}

.c-contact-details__body {
	.c-contact-details--with-form & {
		@include bp('medium') {
			display: grid;
			grid-column-gap: $bsu;
			grid-row-gap: $lsu;
			grid-template-columns: 1fr 1fr;
		}
	}
}

.c-contact-details__list {
}

.c-contact-details__list-item {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: $lsu;
	}
}

.c-contact-details__list-heading {
	@include text-heavy(20px, 28px);
	margin-bottom: $ssu;

	@include bp('large') {
		@include text-heavy(24px, 28px);
	}
}

.c-contact-details__list-name {
	/*	@include text-heavy(18px, 24px);
	@include bp(957px) { // match .c-locations-list__location-heading
		@include text-heavy(24px, 28px);
	}*/

	@include text-heavy(16px, 24px);
}

.c-contact-details__list-position {
	@include text-heavy(16px, 24px);
}

.c-contact-details__numbers {
	padding-top: $bsu;
	@include text-heavy(16px, 24px);

	.c-contact-details__list-heading + & {
		padding-top: 0;
	}
}

.c-contact-details__numbers-item {
	@include flex-extend;
	padding-bottom: $tsu;

	&:not(:last-child) a {
		color: $body-color;
		text-decoration: none;

		&:hover {
			color: $tropical-rain-forest;
		}
	}

	&:last-child {
		padding-bottom: 0;
	}
}

.c-contact-details__numbers-icon {
	display: inline-flex;
	width: 20px;
	padding-top: 4px;
	margin-right: $ssu;
	font-size: 18px;
	text-align: center;
}

.c-contact-details__numbers-value {
	@include text-heavy(14px, 20px);

	a {
		word-break: break-word;
	}

	> p:last-child {
		margin-bottom: 0;
	}
}

.c-contact-details__form {
	align-self: start;
	padding: $lsu $bsu;
	background: $grey--lightest;
	position: relative;

	&:before {
		@include pseudo-element;
		width: calc(100% - $lsu);
		transform: translateX(-50%);
		left: 50%;
		height: 4px;
		top: 0;
		background: linear-gradient(90deg, #00B2A5 15.19%, #6BECCC 82.25%);
	}

	@include bp('medium') {
		padding: $lsu;

		&:before {
			width: calc(100% - $hsu);
		}
	}


	.umbraco-forms-form {
		max-width: none;
	}

	div.umbraco-forms-navigation {
		padding-bottom: 0;
		text-align: right;
	}

	.btn.primary {
		width: 100%;
	}
}
