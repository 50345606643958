﻿// MACRO.BUTTON

.m-button__layout {
	margin: $bsu 0;
}

.m-button {
	@include button;
	@include btn-primary;
}
