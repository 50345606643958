﻿// COMPONENT.SEARCH-RESULT

.c-search-result {
	.ss360-nav {
		.ss360-nav__menu {
			padding-bottom: 10px;

			.ss360-nav__entry {
				@include button("small");
				@include btn-outline;

				.ss360-nav__result-count {
					font-size: 12px;
				}
			}
		}

		.ss360-acl-b,
		.ss360-acl-b--hover:hover,
		.ss360-acl-b--hover:focus {
			background-color: $teal !important;
			border-color: $teal !important;
		}
	}

	.ss360-slider__input-wrap--date > div {
		width: calc(100% - 32px);

		.ss360-slider__date-wrap {
			label {
				width: 50px;
			}
		}
	}

	.ss360-suggests__url {
		color: $tropical-rain-forest;

		&:hover {
			text-decoration: underline;
		}
	}
}
