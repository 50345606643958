// COMPONENT.BASIC-FILTER

.c-basic-filter {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: -$bsu;
	margin-top: -$bsu;
	margin-bottom: $lsu;
}

.c-basic-filter__item {
	padding-left: $ssu;
	padding-top: $ssu;
}

.c-basic-filter__button {
	@include button("small");
	text-wrap: nowrap;
}
