////////////////////////
// HOME HERO - themes //
////////////////////////

.c-home-hero {
	overflow: hidden;
	height: 500px;

	@include bp("large") {
		height: 680px;
	}
}

.c-home-hero--rotator {
	@include flickity-buttons;

	.flickity-viewport {
		height: 100% !important;
	}

	.flickity-slider {
		height: 100% !important;
	}

	.flickity-button {
		top: auto;
		transform: translateY(0);
		bottom: $bsu;

		&.previous {
			left: $bsu;
		}

		&.next {
			right: $bsu;
		}

		@include bp('large') {
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.flickity-page-dots {
		bottom: 10px;
		transform: translateY(calc(-50% - 22px));

		@include bp('large') {
			@include abs-center-h;
			max-width: $layout-wrapper-max-width;
			text-align: left;
			padding: $tsu $hsu;
		}

		@include bp($layout-wrapper-max-width + $lsu) {
			padding: $tsu $lsu;
		}
	}

	.dot {
		width: 24px;
		height: 3px;
		border-radius: 5px;
		background: $grey;
		margin: 0 $tsu;
		box-shadow: 0 0px 6px 3px rgba($black, 0.1);
		outline: 1px solid $grey--light;
		transition: all 0.3s;

		&.is-selected {
			background: $aquamarine;
			outline: 1px solid $aquamarine;
			opacity: 1;
		}

		&:hover {
			background: $teal;
		}
	}

	.flickity-slider {
		transform: translateX(0%) !important;
	}
}

.c-home-hero--rotator__viewport {
	width: 100%;
	height: 100%;
	left: 0% !important;
	opacity: 0 !important;
	transition: opacity 0.8s;

	&.is-selected {
		z-index: 1;
		left: 0% !important;
		opacity: 1 !important;
		transition: opacity 0.8s;
	}

	.c-home-hero-item__figure {
		height: 100%;
	}

	.c-hero__image--mobile {
		display: block;
		height: 100%;
		padding: 0;

		@include bp("large") {
			display: none;
		}
	}

	.c-hero__image--desktop {
		display: none;

		@include bp("large") {
			display: block;
			height: 100%;
			padding: 0;
		}
	}
}

.c-home-hero-item {
	position: relative;
	height: 100%;
}

.c-home-hero-item__figure {
	height: 100%;
}

.fade-black,
.fade-white {
	&:after {
		@include pseudo-element;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.fade-black::after {
	background: linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0.7) 90%, rgba(0, 0, 0, 0.9) 95%, rgba(0, 0, 0, 1.0) 100%);
}

.fade-white::after {
	background: linear-gradient(rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.1) 60%, rgba(255, 255, 255, 0.4) 80%, rgba(255, 255, 255, 0.7) 90%, rgba(255, 255, 255, 0.9) 95%, rgba(255, 255, 255, 1.0) 100%);
}

//////////////////////////////
// HOME HERO - text content //
//////////////////////////////

$hero-content-max-width: 800px;

.c-home-hero-item__content {
	@include abs-center-h;
	bottom: 40px;
	width: 100%;
	max-width: $layout-wrapper-max-width;
	display: flex;
	flex-direction: column;
	padding: $bsu;
	align-items: flex-start;
	padding-bottom: 80px;

	&--text-black {
		color: $body-color;
	}

	&--text-white {
		color: $white;
	}

	&--text-teal {
		color: $teal;
	}

	@include bp('large') {
		padding: $lsu $hsu;
		bottom: 80px;
	}

	@include bp('two-extra-large') { // To avoid text overlapping arrow outline: 1px red solid;
		padding: $lsu 20px;
	}
}

.c-home-hero-item__eyebrow {
	@include text-heavy(13px, 16px);
	color: $white;
	padding: calc(#{$ssu} / 2) $ssu;
	background: $black;
	border-radius: 150px;
	margin-bottom: $ssu;
	max-width: $hero-content-max-width;
}

.c-home-hero-item__heading {
	@include text-black(22px, 24px);
	max-width: $hero-content-max-width;
	margin-bottom: $lsu;

	@include bp('medium') {
		@include text-black(48px, 48px);
	}
}

.c-home-hero-item__cta {
	@include button("medium");
	@include btn-primary;
}

///////////////
// PAGE HERO //
///////////////

$hero-bp: "large";

.c-hero {
	position: relative;

	&--text-black,
	&--text-dark-teal,
	&--text-teal {
		background: $white;
	}

	&--text-white {
		background: $black;
	}

	@include bp("large") {
		&--text-black {
			color: $black;

			.c-hero__eyebrow {
				color: $tropical-rain-forest;
			}
		}

		&--text-white {
			color: $white;
		}

		&--text-teal {
			color: $teal;

			.c-hero__eyebrow {
				color: $tropical-rain-forest;
			}
		}

		&--text-dark-teal {
			color: $tropical-rain-forest;
		}
	}
}

.c-hero__figure {
	@include figure;
	max-height: 450px;
	overflow: hidden;

	.o-fit.c-hero__image {
		max-height: 450px;
		background: none;

		.o-fit__image {
			max-height: 450px;
			object-position: top;
		}
	}
}

///////////////////////
// PAGE HERO - image //
///////////////////////

.c-homepage-hero__image {
	img {
		height: 680px;
	}
}

.c-hero__image--mobile {
	@include bp($hero-bp) {
		display: none;
	}
}

.c-hero__image--desktop {
	@include bp-below($hero-bp) {
		display: none;
	}
}

.c-hero__header {
	padding: $lsu $bsu;
	background: $white;

	@include bp('large') {
		background: transparent;
	}

	@include bp($hero-bp) {
		@include abs-center;
		top: calc(50% - 40px);
		width: 100%;
		max-width: $layout-wrapper-max-width;
	}
}

.c-hero__eyebrow {
	@include text-heavy(16px, 24px);
	max-width: 500px;

	@include bp($hero-bp) {
		@include text-heavy(18px, 24px);
	}
}

.c-hero__heading {
	@include text-black(26px, 28px);
	margin-bottom: $bsu;
	max-width: 650px;

	@include bp($hero-bp) {
		@include text-black(38px, 40px);
		margin-top: $bsu;
	}

	.c-page-header__layout & {
		max-width: 900px;
	}
}

.c-hero__publication-data {
	max-width: 650px;

	&:not(:last-child) {
		margin-bottom: 15px;
	}

	> div {
		margin-bottom: 10px;
	}

	.c-page-header__layout & {
		max-width: 900px;
	}
}

.c-hero__subheader {
	@include text-black(14px, 18px);
	display: inline-block;
	color: $tropical-rain-forest;

	@include bp("medium") {
		@include text-black(16px, 24px);
	}

	.c-hero--text-white & {
		color: $tropical-rain-forest;

		@include bp("large") {
			color: $white;
		}
	}
}

.c-hero__seperator {
	margin-left: -4px;
}

.c-page-header__intro {
	@include text-book(16px, 24px);
	font-weight: bold;
	max-width: 650px;

	.c-page-header__layout & {
		max-width: 900px;
	}

	a, a:visited {
		@include custom-text-link;
	}
}

///////////////////////
// PAGE HERO - VIDEO //
///////////////////////

.c-hero__video {
	display: none;

	@include bp("medium") {
		display: block;
		width: 100%;
		max-height: 80vh;
		object-fit: cover;

		~ .c-hero__image {
			display: none;
		}
	}
}

/////////////
// PRIMARY //
/////////////

.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;

	@include flex-extend;
	flex-direction: column-reverse;

	@include bp($hero-bp) {
		flex-direction: row;
		align-items: stretch;
	}

	.c-hero__body {
		@include flex-extend;
		align-items: center;
		padding: $bsu;

		@include bp($hero-bp) {
			width: 100%;
			min-height: 200px;

			.c-hero--has-image & {
				width: 50%;
				min-height: 540px;
				padding-left: 0;
				padding-right: 0;
			}
			// Offset from image
			margin-bottom: $lsu;
		}
	}

	.c-hero__body-content {
		flex-direction: column;
		padding: $bsu;

		@include bp($hero-bp) {
			@include layout-wrapper;

			.c-hero--has-image & {
				max-width: none;
				padding: 0;

				@include bp-below($layout-wrapper-max-width) {
					padding-left: $bsu;
				}

				@include layout-split(left, 50%);
				padding-right: $lsu;
			}
		}
	}

	.c-hero__title {
		@include text-black(38px, 40px);
		;
		position: relative;
		z-index: 2;
		margin-bottom: $ssu;

		@include bp("medium") {
			margin-bottom: $bsu;
		}

		@include bp($hero-bp) {
			@include text-black(48px, 48px);
			margin-bottom: $lsu;
		}
	}

	.c-hero__text {
		@include text-book(18px, 28px);

		@include bp($hero-bp) {
			flex-grow: 1;
		}
	}

	.c-hero__date {
		@include text-book(12px, 16px);
		margin-bottom: 2em;
	}

	.c-hero__scroll-icon {
		margin-top: $lsu;
		color: $red;
		text-align: center;
		font-size: 24px;
		display: none;

		@include bp($hero-bp) {
			display: block;
		}
	}

	.c-hero__figure {
		@include figure;
		flex-grow: 1;
		position: relative;
		overflow: hidden;
		max-height: 80vh;
		max-height: min(450px, 80vh);
		border-radius: 0 0 0 4px;

		@include bp($hero-bp) {
			max-height: none;
		}
	}

	.c-hero__image {
		display: block;
		width: 100%;

		@include bp($hero-bp) {
			height: 100%;
			padding: 0 !important;
			object-fit: cover;
		}
	}
}
///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: ($bsu * 3) 0 0; // Match .o-layout-page

	.c-hero__secondary-title {
		width: 100%;
		text-align: center;
	}

	.c-hero__secondary-title-text {
		display: block;
		color: $brand;
		margin-bottom: $bsu;

		@include bp($hero-bp) {
			padding: 0 20px;
			margin-bottom: 0;
		}
	}

	.c-hero__secondary-introduction {
		@include text-book(18px, 28px);
		flex: 0 1 auto;

		@include bp($hero-bp) {
			margin-top: 4px; // Align better with secondary title
			margin-left: 40px;
		}
	}
}
