﻿// CONTROL.LOGO-GRID

.c-logo-grid {
	@include control;
}

.c-logo-grid__layout {
	@include layout-wrapper;
}

.c-logo-grid__heading {
	@include control__heading;
}

.c-logo-grid-list {
	display: grid;
	padding: 0;
	margin: 0;
	list-style: none;
	grid-gap: $ssu;

	&--extra-small {
		grid-template-columns: repeat(2, 1fr);

		@include bp("small") {
			grid-template-columns: repeat(4, 1fr);
		}

		@include bp("medium") {
			grid-template-columns: repeat(6, 1fr);
		}

		@include bp("large") {
			grid-template-columns: repeat(8, 1fr);
		}
	}

	&--small {
		grid-template-columns: repeat(2, 1fr);

		@include bp("medium") {
			grid-template-columns: repeat(4, 1fr);
		}

		@include bp("large") {
			grid-template-columns: repeat(6, 1fr);
		}
	}

	&--medium {
		grid-template-columns: repeat(2, 1fr);

		@include bp("medium") {
			grid-template-columns: repeat(3, 1fr);
		}

		@include bp("large") {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&--large {
		grid-template-columns: repeat(1, 1fr);

		@include bp("small") {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

.c-logo-grid-list__item {
	@include hover-bounce;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 1px solid $grey--lightest;
	padding: $ssu;
}

.c-logo-grid-list__item-figure {
	display: flex;
	justify-content: center;
	align-items: center;

	.c-logo-grid-list--extra-small &,
	.c-logo-grid-list--small & {
		aspect-ratio: 1/1;
	}

	.c-logo-grid-list--medium & {
		aspect-ratio: 4/3;
	}

	.c-logo-grid-list--large & {
		aspect-ratio: 16/9;
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.c-logo-grid-list__item-link {
	@include text-book(16px, 24px);
}
