﻿// CONTROL.GALLERY-ROTATOR

.c-gallery-rotator {
	@include control;
	@include flickity-buttons;

	.flickity-button {
		top: calc(50% - 28px); //remove caption height to align arrows
	}

	.previous {
		left: $bsu;
	}

	.next {
		right: $bsu;
	}
}

.c-gallery-rotator__layout {
	@include layout-wrapper;
}

.c-gallery-rotator__heading {
	@include control__heading;
}

.c-gallery-rotator__slides {
	@include list-reset;

	&:focus-visible {
		outline: none;
	}
}

.c-gallery-rotator__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-gallery-rotator__slide-link {
	@include figure;
}

.c-gallery-rotator__caption {
	@include control__figcaption;

	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;

	.c-gallery-rotator__slide.is-selected & {
		opacity: 1;
	}
}

.c-gallery-rotator__caption-text {
	@include text-book(14px, 24px, italic);
	flex: 1 2 auto;
	gap: $bsu;
}

.c-gallery-rotator__caption-count {
	@include text-heavy(16px, 24px);
	display: inline;
	padding-left: $lsu;
	white-space: nowrap;
}
