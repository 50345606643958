// COMPONENT.JUMPLINK-BAR

.c-jumplink-bar {
	padding: 20px 0;
	background: $midnight-green;
	width: 100%;
	position: relative;

	&.stick {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1;
		top: 80px;

		@include bp("large") {
			top: 100px;
		}
	}
}

.c-jumplink-bar__wrapper {
	@include layout-wrapper;
	display: block;
	align-items: center;
	width: 100%;
	position: relative;

	@include bp("medium") {
		display: flex;
	}
}

.c-jumplink-bar__container {
	display: flex;
	overflow: auto;
	width: 100%;
	padding-bottom: 20px;

	@include bp("medium") {
		padding-bottom: 0px;
	}
}

.c-jumplink-bar__title {
	display: none;

	@include bp("medium") {
		display: block;
		color: $aquamarine;
		margin-right: 20px;
		text-wrap: nowrap;
		@include text-heavy(16px, 24px);
	}
}

.c-jumplink-bar__link {
	padding: 5px 20px;
	color: $white;
	transition: all 0.3s;
	border-radius: 50px;
	text-wrap: nowrap;
	white-space: nowrap;
	@include text-heavy(14px, 20px);

	@include bp("large") {
		@include text-heavy(16px, 24px);
	}

	&.active {
		background: $black;
	}

	&:hover {
		color: $aquamarine;
	}
}

/* To compensate for the anchor tag injected above first control */
.c-jump-target:first-child {
	+ * {
		margin-top: 0px;
	}
}

/* Arrows */

.c-jumplink-bar__tabs-arrow {
	display: none;
	width: 25px;
	height: 25px;
	position: absolute;
	bottom: 10px;

	span {
		cursor: pointer;
		display: block;
		height: 25px;
		width: 25px;
		font-size: 25px;
		color: $aquamarine;
	}

	.c-jumplink-bar.Scrolling & {
		display: block;
	}

	&.left {
		left: 15px;
	}

	&.right {
		right: 15px;
	}
}

/* Scroller */

.c-jumplink-bar__container::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.c-jumplink-bar__container::-webkit-scrollbar-thumb {
	background-color: $aquamarine;
	border: 0px;
}

.c-jumplink-bar__container::-webkit-scrollbar-track {
	background: $rich-black;
	margin: 0 50px;
}
