﻿.c-footnotes {
	@include layout-wrapper;
	@include body-text;
}

.c-footnotes_heading {
	@include text-heavy(18px, 24px);
}

.c-footnotes__list {
}

.c-footnotes__list-item {
	.c-footnotes__list & {
		list-style: decimal;
	}
}

.c-footnotes__list-item-text {
	@include body-text;
	display: flex;
	align-items: flex-start;
	padding-right: 10px;
	max-width: calc(100% - 40px);
	word-break: break-word;
}

.c-footnotes__list-item-link {
	@include link-transition;
	height: 30px;
	width: 40px;
	min-width: 40px;
	display: inline-block;
	background: url('../../Content/images/interface/footnote-arrow.svg') no-repeat center center;
	margin-top: -3px;
}
