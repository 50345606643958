﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	@include layout-wrapper;
	padding-top: $ssu;
	padding-bottom: $ssu;

	.c-hero--text-white & {
		color: $white;
	}
}

.c-breadcrumb__list {
	@include list-reset;
	@include list-inline;
}

.c-breadcrumb__link {
	@include text-book(12px, 16px);
}

.c-breadcrumb__current {
	@include text-book(12px, 16px);
	@include link-plain;
	color: $black;

	.c-hero--text-white & {
		color: $white;
	}
}

.c-hero--text-white .c-breadcrumb .c-breadcrumb__link:hover {
	color: $aquamarine;
}