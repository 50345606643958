// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;
$navigation-bp: $header-bp;

.c-navigation {
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	@include bp($navigation-bp) {
		display: block;
		width: auto;
		background-color: transparent;
	}

	&[aria-expanded="true"] {
		display: block;
		position: absolute;
		top: $header-height;
		top: var(--header-height);
		left: 0;
		height: calc(100vh - #{$header-height});
		height: calc(100vh - var(--header-height));
		overflow: auto;
	}
}

.c-navigation__items {
	@include list-reset;
	padding-top: $tsu;

	@include bp($navigation-bp) {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		padding: 0;
	}
}

.c-navigation__item {
	border-bottom: 1px solid $grey--lighter;

	@include bp($navigation-bp) {
		position: relative;
		border: 0;
		vertical-align: bottom;
		display: flex;
		align-items: stretch;
	}

	&.home {

		@include bp("medium") {
			display: none;
		}
	}
}

.c-navigation__item--secondary {
	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__link {
	@include text-heavy(18px, 28px);
	@include link-transition;
	display: block;
	padding: 14px $hsu 14px $bsu;
	color: $body-color;
	position: relative;
	text-align: left;

	.c-navigation__link-eyebrow {
		@include text-book(14px, 16px);
		@include link-transition;
		color: $tropical-rain-forest;
	}

	@include bp($navigation-bp) {
		display: inline-block;
		padding: 32px 20px 22px 10px;
		white-space: nowrap;

		&:hover {
			color: $tropical-rain-forest;
			@include link-transition;

			.c-navigation__link-eyebrow {
				font-weight: bold;
				@include link-transition;
			}
		}

		.c-navigation__item[aria-expanded="true"] & {
			border-color: $aquamarine;
		}
	}

	@include bp(1200px) {
		display: inline-block;
		padding: 35px 25px 20px 25px;
		white-space: nowrap;

		&:after {
			content: '';
			position: absolute;
			width: 0;
			left: 0;
			bottom: 0;
			height: 3px;
			background: white;
			transition: width .3s ease
		}

		&:hover {
			color: $tropical-rain-forest;
			@include link-transition;

			.c-navigation__link-eyebrow {
				font-weight: bold;
				@include link-transition;
			}

			&:after {
				background: linear-gradient(270deg, #55EFC4 18.6%, #00B2A5 76.86%);
				width: 100%;
				transition: width .3s ease
			}
		}

		.c-navigation__item[aria-expanded="true"] & {

			&:after {
				background: $tropical-rain-forest;
				height: 4px;
				width: 100%;
				transition: width .3s ease
			}
		}
	}

	&.home {
		display: none;
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp($navigation-bp) {
		line-height: 1;
	}
}

.c-navigation__expander {
	position: absolute;
	right: $bsu * 1.5;
	top: calc(50% + 8px);
	transform: translateY(-50%);
	@include link-transition(transform);
	transform-origin: 50% 50%;

	@include bp($navigation-bp) {
		right: 0;
		top: calc(50% + 12px);
	}

	&::before {
		display: block;

		@include font-icon;

		@include bp-below($navigation-bp) {
			content: $iconf-arrow-right-small;
			font-size: 1.5em;

			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(90deg);
			}
		}

		@include bp($navigation-bp) {
			content: $iconf-arrow-down-small;
			font-size: 0.7em;


			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(-180deg);
			}
		}
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	@include list-reset;
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp($navigation-bp) {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);
	}

	[aria-expanded="true"] > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($navigation-bp) {
		@include ui-border("bottom");
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include text-book(14px, 22px);
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp($navigation-bp) {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__footer {
	padding: $bsu;

	.c-header__language {
		display: block;
		@include list-reset;
	}

	.c-header__secondary-nav {
		display: block;
		@include list-reset;
		margin: 20px 0;

		.c-header__secondary-nav-link {
			width: 100%;
			max-width: 360px;
			text-align: center;
			display: block;
			margin: 0 auto;
		}
	}

	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__social-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-navigation__social-item {
	padding-left: $bsu;
	padding-top: $bsu;
}

.c-navigation__social-link {
	@include icon-wrapper;
	@include circle(40px);
	@include link-transition;
	font-size: 20px;
	background: $aquamarine;
	color: $black;
}

// Language container - append around used to insert language picker in here when it's visible
.c-navigation__language {
	padding: $bsu;

	@include bp('medium') {
		display: none;
	}
}
