﻿.c-search-result {
	@include bp-below(990px) {
		.ss360-filter__heading {
			margin-bottom: $bsu;
		}
		#ss360-filter {
			padding-top: calc(#{$header-height} + $bsu);
			padding-bottom: calc(#{$header-height} + $bsu);
		}

		.ss360-layer__content--filter-left:not(.ss360-filter--slide) .ss360-filter .ss360-close-button {
			top: $header-height !important;
		}

		.ss360-layer__content--filter-left .ss360-filter__button,
		.ss360-layer__content--filter-left .ss360-filter__group {
			padding-right: 0;
		}
	}
}
