// CONTROL.LISTING-VERBOSE

@use "sass:math";

$listing-verbose-bp: "medium";

.c-listing-verbose {
	@include list-reset;
	margin-top: -$bsu;
}

.c-listing-verbose__item {
	width: 100%;
	container: verboseListing / inline-size
}

.c-listing-verbose__item-link {
	@include ui-border("bottom");
	@include link-transition(border-color, background);
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-direction: column;

	&:hover {
		box-shadow: none;
		border-color: $teal;
		background: $grey--lightest;

		.c-listing-verbose__item-heading {
			color: $teal;
		}
	}
}

.c-listing-verbose__item-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: $bsu;
	color: $body-color;
}

.c-listing-verbose__item-heading {
	@include text-heavy(18px, 24px);
	margin-bottom: $ssu;
}

.c-listing-verbose__item-date {
	@include carousel-item-date;
	display: block;
	margin-bottom: $ssu;
}

.c-listing-verbose__item-text {
	@include text-book(16px, 24px);
	color: $body-color;
	margin-bottom: 0;
}

.c-listing-verbose__item-figure {
	@include figure;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
	order: -1;
}

.c-listing-verbose__item-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@container verboseListing (min-width: 800px) {
	.c-listing-verbose__item-link {
		flex-direction: row;
	}

	.c-listing-verbose__item-content {
		flex-direction: row;
		width: calc(100% - 360px);
		flex-grow: 0;
		flex-shrink: 0;
		padding: $lsu $bsu+$lsu;
	}

	.c-listing-verbose__item-head {
		width: percentage(math.div(2, 5));
		flex-grow: 0;
		flex-shrink: 0;
	}

	.c-listing-verbose__item-heading {
		margin-right: $lsu;
	}

	.c-listing-verbose__item-body {
		width: percentage(math.div(3, 5));
		flex-grow: 0;
		flex-shrink: 0;
	}

	.c-listing-verbose__item-figure {
		width: 360px;
		order: 3;
	}
}
