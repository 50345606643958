@media print {

	.c-header {
		&,
		&.is-sticky {
			position: absolute;

			&.is-up {
				transform: none;
			}
		}

		box-shadow: none;
	}

	.c-footer,
	.c-footer__back-to-top-wrapper {
		display: none;
	}

	.c-header__mobile-button {
		display: none;
	}

	.c-cta__body {
		page-break-inside: avoid;
	}

	.c-embed {
		page-break-inside: avoid;
	}

	.m-quote {
		page-break-inside: avoid;
	}

	.c-listing-stacked__item {
		page-break-inside: avoid;
	}

	.c-related-item {
		page-break-inside: avoid;
	}

	.c-subnav__item {
		display: block;
		page-break-inside: avoid;

		&.is-selected::before {
			top: 0;
			height: 100%;
		}
	}

	.c-share__items {
		page-break-inside: avoid;
	}

	.l-page__footer .c-share {
		display: none;
	}

	.c-footer-back-to-top {
		display: none !important;
	}

	.c-newsletter {
		display: none;
	}

	// RMV SPECIFIC PRINT
	.c-page-header {
		background-color: transparent;
	}

	.c-subnav {
		display: none;
	}

	.c-jumplink-bar {
		display: none;
	}

	.c-hero__figure {
		display: none;
	}

	.l-page__main-controls {
		margin-top: 0;
	}

	.c-hero.c-hero--text-white {
		background-color: transparent;
		color: $black;

		.c-breadcrumb__current {
			color: $black;
		}
	}

	.c-insight-news__tabs,
	.c-insight-news__filter {
		display: none;
	}

	.pagi {
		display: none;
	}

	// ## CONTROLS

	.c-rte__alignment--inset {
		max-width: none;
	}

	.l-page__sidebar .vue-people-list-control {
		//page-break-inside: avoid;

		.c-people-list {
			columns: 2;
		}
	}

	.c-expert__list-wrapper {
		page-break-inside: avoid;
	}

	.c-latest-insights {
		display: none;
	}

	.c-faq {
		.c-faq__item-icon {
			display: none;
		}

		.c-faq__item-body {
			display: block;
		}
	}

	.c-expand-collapse {
		.c-expand-collapse__item-btn {
			display: none;
		}

		.c-expand-collapse__item-body {
			display: block;
		}
	}

	// ## INSIGHTS & NEWS
	.l-page__sidebar .c-share {
		display: none;
	}

	.c-footnotes__list-item-link {
		display: none;
	}

	// ## PERSON DETAIL
	.c-person__hero {
		display: flex;
		flex-wrap: wrap;

		.c-breadcrumb {
			width: 100%;
		}
	}

	.c-person__hero-button-container {
		display: block;

		.c-person__hero-phone {
			&.desktop {
				display: flex;
			}

			&.mobile {
				display: none;
			}
		}

		.c-person__hero-vcard {
			display: none;
		}

		.c-person__hero-phone,
		.c-person__hero-mobile,
		.c-person__hero-email,
		.c-person__hero-linkedin {
			display: flex;
			width: 100%;
			padding: 0 0 10px;
			margin: 0;
			justify-content: flex-start;
			border: none;
			border-radius: 0 !important;
			box-shadow: none;

			[class$="-value"] {
				@include text-book(16px, 24px);
			}

			[class^=iconf] {
				margin-right: 8px;
			}
		}

		.c-person__hero-email-value,
		.c-person__hero-linkedin-value {
			display: block;
		}
	}

	.c-person__hero-image-wrapper {
		display: none;
	}

	.c-person__hero-bio-image-wrapper {
		display: block;
		min-width: 25%;
		margin-right: 10px;
	}

	.c-person__hero-bio-image {
		border: 1px solid $grey;
	}

	.c-people-detail__tabs {
		display: none;
	}

	.c-people-tab__content {
		display: block !important;
		//.l-page__main-controls,
		.l-page__sidebar {
			page-break-inside: avoid;
		}

		.l-page__sidebar {
			display: none;
		}

		&:first-child .l-page__sidebar {
			display: block;
		}

		&#insights {
			display: none !important;
		}
	}
}
