﻿// CONTROL.EMBED

.c-embed {
	@include control;
}

.c-embed__layout {
	@include layout-wrapper;
}

.c-embed__heading {
	@include control__heading;
}

.c-embed__content {
	max-width: 100%;
	// overflow: hidden;
}

//  Commented out for release three
#SubscribeForm {
	height: 1650px;
	margin-top: -40px;

	@include bp(655px) {
		height: 1450px;
	}

	@include bp("medium") {
		height: 950px;
	}

	@include bp(1030px) {
		height: 850px;
	}
}