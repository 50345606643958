// OBJECT.CONTROL

@mixin control {
	margin-top: var(--control-gap);
	margin-bottom: var(--control-gap);

	@include bp("large") {
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

@mixin control__heading {
	@include text-black(20px, 28px);
	margin-bottom: 1em;

	@include bp("large") {
		@include text-black(28px, 30px);
	}
}

@mixin control__figcaption {
	@include ui-border("bottom");
	@include text-book(16px, 24px);
	padding: $bsu 0;
}
