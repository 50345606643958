// COMPONENT.RELATED-ITEMS

.c-related-items {
	@include control;
}

.c-related-items__layout {
	@include layout-wrapper;
}

.c-related-items__heading {
	@include control__heading;
}

.c-related-items__intro {
	@include body-text;
	margin-bottom: $bsu;

	p {
		@include text-book(16px, 24px);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-related-items__list {
	@include list-reset;
	@include list-stacked;
}

.c-related-item {
}

.c-related-item__link {
	@include link-transition;
	@include ui-border('bottom');
	position: relative;
	display: block;
	padding: $bsu;
	background-color: $white;
	padding-left: $bsu * 2.5;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		border-color: $brand;
		box-shadow: none !important;
		margin-left: -2px;
		.c-related-item__text {
			color: $tropical-rain-forest;
		}
	}

	@include bp('large') {
		padding-left: $bsu * 4;
	}
}

.c-related-item__text {
	@include text-heavy(16px, 28px);
	color: $body-color;

	@include bp('large') {
		@include text-black(18px, 24px);
	}
}

.c-related-item__icon {
	@include button('small', true);
	@include btn-primary;
	position: absolute;
	top: $bsu * 1.5;
	transform: translateY(-50%);
	left: 0;

	@include bp('large') {
		left: $bsu;
	}
}

.c-related-item__meta {
	@include text-heavy(13px, 16px);
	display: block;
	margin-top: $tsu;
	color: $midnight-green;
}
