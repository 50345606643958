/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

$megamenu__column-gap: 20px;
$megamenu__column-number: 4;
$megamenu__column-number--with-promos: 2;
$megamenu__column-number--with-promos-vars: calc(4 - var(--megamenu-number-promos, 2));

$megamenu-bp: $header-bp;

.c-megamenu {
	--megamenu-column-gap: 20px;

	@include bp("extra-large") {
		--megamenu-column-gap: 20px;
	}

	display: none;
	background: $white;

	&[aria-expanded="true"],
	[aria-expanded="true"] > & {
		display: block;
	}

	&[aria-expanded="true"] {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp($megamenu-bp) {
		transition: max-height 0.5s ease-in-out;
		position: absolute;

		.c-navigation__item--megamenu[aria-expanded="true"] & {
			z-index: 1;
		}

		top: $header-height;
		top: var(--header-height);
		left: 0;
		display: block;
		width: 100%;
		max-height: 0;
		visibility: hidden;
		overflow: auto;
		text-align: left;
		background: rgba($white, 0.95);
		box-shadow: 0 5px 4px -6px rgba($black, 0.25);

		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			max-height: 500px;
			max-height: calc(100vh - var(--header-height));
			visibility: visible;
		}

		&.allow-animations,
		.allow-animations > & {
			animation: 0.5s becomeHidden forwards;
		}

		&.allow-animations[aria-expanded="true"],
		.allow-animations[aria-expanded="true"] > & {
			animation: 0.5s becomeVisible forwards;
		}
	}
}

.c-megamenu__wrapper {
	@include clearfix;

	@include bp($megamenu-bp) {
		max-width: em($layout-wrapper-max-width);
		padding: 0 $bsu;
		padding-top: $bsu * 3;
		padding-bottom: $bsu * 3;
		margin: 0 auto;
		display: flex;
		position: relative;
	}
}

.c-megamenu__intro {
	margin-bottom: $bsu;
	display: none;

	@include bp($megamenu-bp) {
		width: 25%;
		display: block;
		padding-right: 40px;
	}
}

.c-megamenu__link-eyebrow {
	@include text-book(16px, 24px, bold);
	font-weight: bold;
	color: $tropical-rain-forest;
}

.c-megamenu__intro-heading {
	display: inline-block;
	margin-bottom: $bsu;
	padding: 0;
	color: $black;

	@include text-black(28px, 30px);
}

.c-megamenu__intro-button {
	@include button("medium");
	@include btn-primary;
}

.c-megamenu__body {
	@include bp($megamenu-bp) {
		@include flex-extend;
		width: 75%;
		justify-content: space-between;
	}
}

.c-megamenu__item-section {
	border-left: 1px solid $grey--light;
	padding: 0 40px;
}

.c-megamenu__section-small {
	width: 100%;

	@include bp($megamenu-bp) {
		width: 33%;
	}
}

.c-megamenu__section-big {
	width: 100%;

	@include bp($megamenu-bp) {
		width: 66%;
	}
}

.c-megamenu__body--single-promo {
	--megamenu-number-promos: 1;
}

.c-megamenu__section-heading {
	font-size: 12px;
	font-weight: 800;
	line-height: 16px;
	letter-spacing: 0.48px;
	text-transform: uppercase;
	color: $grey;

	@include bp($megamenu-bp) {
		color: $grey--dark;
		margin-bottom: 30px;
	}
}

.c-megamenu__section-landing-link {
	@include text-heavy(16px, 24px);
	margin-left: $lsu;
	display: inline-block;
	color: $body-color;
	padding: $bsu 0;

	@include bp($megamenu-bp) {
		display: none;
	}
}

.c-megamenu__items {
	@include list-reset;
	flex-grow: 1;
	padding: $ssu 0;
	margin-bottom: $ssu;

	@include bp($megamenu-bp) {
		columns: 1;
		padding: 0 var(--megamenu-column-gap) 0 0;
		column-gap: var(--megamenu-column-gap);

		.c-megamenu__section-small & {
			columns: 1;
		}

		.c-megamenu__section-big & {
			columns: 2;
		}
	}
}

.c-megamenu__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($megamenu-bp) {
		display: block;
		margin: 0;
		break-inside: avoid;
	}
}

.c-megamenu__item--overview {
	@include bp($megamenu-bp) {
		display: none;
	}
}

.c-navigation__item {
	&.c-navigation__item--megamenu {
		@include bp($megamenu-bp) {
			position: static;
		}
	}
}

.c-megamenu__heading {
	position: relative;
}

.c-megamenu__link {
	@include text-book(16px, 24px);
	font-weight: bold;
	color: $black;
	display: block;
	padding: $tsu 0;
	position: relative;

	&:before {
		display: none;
	}

	@include bp($megamenu-bp) {
		padding: 0;
		width: fit-content;
		margin-bottom: 5px;
		border-bottom: 1px solid transparent;

		&:hover {
			@include link-transition;
			color: $tropical-rain-forest;
			border-bottom: 1px solid $tropical-rain-forest;
		}
	}
}

.c-megamenu__link-icon {
	&::before {
		@include font-icon;
		content: $iconf-arrow-right;
	}

	display: none;

	@include bp($megamenu-bp) {
		position: absolute;
		right: -25px;
		opacity: 0;
		transition: all 0.3s ease;
		display: block;
		top: 50%;
		transform: translateY(-50%);

		.c-megamenu__link:hover &,
		.c-megamenu__link:focus & {
			opacity: 1;
		}
	}
}

.c-megamenu__promo-list {
	display: none;

	@include bp($megamenu-bp) {
		// Pretend the last column has a gap as well, divide the full width, then account for that extra gap
		$megamenu__column-width: calc(((100% + #{$megamenu__column-gap}) / #{$megamenu__column-number}) - #{$megamenu__column-gap});
		$megamenu__column-width-vars: calc(((100% + var(--megamenu-column-gap)) / #{$megamenu__column-number}) - #{$megamenu__column-gap});

		// Difference between normal and reduced number of columns
		$megamenu__column-number--promos: $megamenu__column-number - $megamenu__column-number--with-promos;
		$megamenu__column-number--promos-vars: var(--megamenu-number-promos, #{$megamenu__column-number - $megamenu__column-number--with-promos});

		// Column width for each column space taken, plus the appropriate number of gaps between columns
		$megamenu__promo-width: calc((#{$megamenu__column-width} * #{$megamenu__column-number--promos}) + calc(#{$megamenu__column-gap} * calc(#{$megamenu__column-number--promos} - 1)));
		$megamenu__promo-width-vars: calc((#{$megamenu__column-width-vars} * #{$megamenu__column-number--promos-vars}) + calc(#{$megamenu__column-gap} * calc(#{$megamenu__column-number--promos-vars} - 1)));

		width: $megamenu__promo-width;
		width: $megamenu__promo-width-vars;
		flex-shrink: 0;

		@include flex-extend;
	}
}

.c-megamenu__promo-item {

	@include bp($megamenu-bp) {
		width: calc(((100% + #{$bsu}) / 2) - #{$bsu});
		width: calc(((100% + #{$bsu}) / var(--megamenu-number-promos, 2)) - #{$bsu});
		margin-left: $bsu;

		&:first-child {
			margin-left: 0;
		}
	}

	.c-megamenu__promo-image {
		border-radius: 4px;
		overflow: hidden;
		@include link-transition;
	}

	.c-megamenu__promo-title {
		display: block;
		padding: 20px;

		.c-megamenu__promo-title-text {
			color: $black;
			@include link-transition;
		}
	}

	&:hover {
		.c-megamenu__promo-image {
			opacity: .7;
		}

		.c-megamenu__promo-title-text {
			color: $teal;
		}
	}
}

.c-megamenu__close {
	display: none;

	@include bp("large") {
		display: block;
		right: 15px;
		top: 15px;
		position: absolute;
		font-size: 30px;
		transition: all 0.3s;

		&:hover {
			color: $teal;
			cursor: pointer;
			transition: all 0.3s;
		}
	}
}
